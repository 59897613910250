.testimonials {
    padding: 50px;
    background: url("../images/imgs/bg-body1.jpg");
    border-bottom: 1px solid #444;
    .container {
        .testimonials-container {
            width: 100%;
            .title {
                text-align: center;
                position: relative;
                padding-bottom: 15px;
                h5 {
                    margin-bottom: 13px;
                    font-size: 14px;
                    font-weight: 600;
                    letter-spacing: 1px;
                    color: $mainColor;
                    text-transform: uppercase;
                }
                h3 {
                    font-size: 36px;
                    font-weight: 600;
                    color: #fff;
                }
                &::after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 30px;
                    height: 1px;
                    background-color: $mainColor;
                }
            }
            .testimonials-wraper {
                width: 100%;
                margin-top: 30px;
                .testimonial {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .avatar {
                        width: 120px;
                        height: 120px;
                        border-radius: 50%;
                        position: relative;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: 50%;
                        }
                        &::before {
                            content: "";
                            width: 5px;
                            height: 5px;
                            border: 1px solid $mainColor;
                            position: absolute;
                            top: 50%;
                            left: -50px;
                            transform: translateY(-50%);
                            transform: rotate(45deg);
                            border-top-color: transparent;
                            border-right-color: transparent;
                        }
                        &::after {
                            content: "";
                            width: 5px;
                            height: 5px;
                            border: 1px solid $mainColor;
                            position: absolute;
                            top: 50%;
                            right: -50px;
                            transform: translateY(-50%);
                            transform: rotate(45deg);
                            border-bottom-color: transparent;
                            border-left-color: transparent;
                        }
                    }
                    .testimonial-details {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        p {
                            width: 75%;
                            margin: auto;
                            margin-top: 20px;
                            margin-bottom: 10px;
                            padding-top: 50px;
                            text-align: center;
                            font-size: 18px;
                            font-style: italic;
                            line-height: 1.6;
                            color: #b1b0b0;
                            position: relative;
                            &::before {
                                content: "“";
                                position: absolute;
                                top: 0;
                                left: 50%;
                                font-size: 100px;
                                font-weight: bold;
                                font-style: normal;
                                line-height: 1;
                                pointer-events: none;
                                width: 1em;
                                margin-left: -0.5em;
                                color: $mainColor;
                            }
                        }
                    }
                }
            }
            &.ar {
                font-family: "Noto Kufi Arabic" !important;
            }
        }
    }
    .parteners {
        margin-top: 30px;
        .swiper {
            .swiper-slide {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                    width: 100%;
                    object-fit: cover;
                    filter: grayscale(100%);
                    &:hover {
                        filter: grayscale(0);
                        transition: all ease-in-out 0.2s;
                    }
                }
            }
        }
    }
    .last-p {
        width: 90%;
        margin: auto;
        margin-top: 20px;
        padding-top: 50px;
        text-align: center;
        font-size: 18px;
        font-style: italic;
        line-height: 1.6;
        color: #b1b0b0;
    }
}

@media screen and (max-width: 480px) {
    .testimonials {
        padding: 50px 0;
        .container {
            width: 95%;
            .testimonials-container {
                .testimonials-wraper {
                    .testimonial {
                        .testimonial-details {
                            p {
                                width: 95%;
                            }
                        }
                    }
                }
            }
        }
        .parteners {
            margin-top: 20px;
            .swiper {
                .swiper-slide {
                    img {
                        width: 50%;
                    }
                }
            }
        }
        .last-p {
            width: 95%;
        }
    }
}
