.login-register-page {
    .page-intro {
        height: 100vh;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        .overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
        }
        .rec {
            position: absolute;
            top: -184px;
            right: 120px;
            width: 368px;
            height: 368px;
            border-radius: 50%;
            opacity: 0.3;
            background: rgba(0, 0, 0, 0.93);
            &.rec-bottom {
                top: unset;
                bottom: -120px;
                left: 0;
                background: rgba(0, 0, 0, 0.93);
            }
        }
        .container {
            width: 100%;
            .page-intro-container {
                width: 80%;
                height: fit-content;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                .page-navigation {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 30px;
                    a,
                    span {
                        display: block;
                        margin-right: 15px;
                        font-size: 16px;
                        font-weight: 600;
                        color: #fff;
                        &.page-name {
                            color: $mainColor;
                        }
                    }
                }
                .page-title {
                    text-transform: uppercase;
                    font-size: 60px;
                    font-weight: 600;
                    line-height: 1.2;
                    color: #fff;
                    text-align: center;
                }
            }
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -7px;
            width: 100%;
            height: 50px;
            background-image: url(../images/imgs/banner-bg-shape.png);
            background-repeat: repeat-x;
            background-position: center;
        }
    }
    .login-container {
        width: 50%;
        margin: auto;
        margin-top: 80px;
        box-shadow: 0px 30px 70px rgba(1, 15, 28, 0.1);
        padding: 50px 60px 70px;
        background-color: #fff;
        .title-box {
            text-align: center;
            h3 {
                font-weight: 600;
                font-size: 28px;
                margin-bottom: 4px;
                line-height: 1.2;
            }
            p {
                font-size: 16px;
                font-weight: 400;
                color: $textColor;
                margin-bottom: 15px;
                line-height: 26px;
                a {
                    color: $mainColor;
                }
            }
        }
        .msg-box{
            width: 100%;
            .err, .success{
                display: flex;
                align-items: center;
                background-color: #f74f4f;
                padding: 10px;
                margin-bottom: 10px;
                small{
                    font-size: 18px;
                    font-weight: 600;
                    color: rgb(112, 9, 9);
                    display: inline-block;
                    margin-right: 5px;
                }
                p{
                    font-size: 16px;
                    font-weight: 500;
                    color: #fff;
                }
                &.success{
                    background-color: rgb(101, 184, 101);
                    small{
                        color: rgb(11, 68, 11);
                    }
                }
            }
        }
        .form {
            width: 90%;
            margin: auto;
            margin-top: 30px;
            display: block;
            .field {
                margin-bottom: 20px;
                label {
                    display: block;
                    margin-bottom: 10px;
                    color: $textColor;
                    font-size: 16px;
                    font-weight: 400;
                }
                input {
                    outline: none;
                    background-color: #fff;
                    height: 56px;
                    width: 100%;
                    line-height: 56px;
                    font-size: 14px;
                    color: $headingColor;
                    padding-left: 26px;
                    padding-right: 26px;
                    border: 1px solid #e0e2e3;
                    border-radius: 10px;
                }
                .forget-password {
                    display: block;
                    margin-top: 20px;
                    text-align: right;
                    font-size: 16px;
                    color: $mainColor;
                }
            }
            .submit {
                display: block;
                width: 100%;
                border: none;
                background-color: transparent;
                width: 100%;
                background-color: $mainColor;
                border: 1px solid $mainColor;
                color: #fff;
                font-size: 14px;
                font-weight: 600;
                border-radius: 50px;
                text-align: center;
                text-transform: capitalize;
                line-height: 1;
                padding: 22px 41px;
                cursor: pointer;
                transition: all ease-in-out 0.5s;
                &:hover {
                    background-color: #fff;
                    border-color: $mainColor;
                    color: $mainColor;
                }
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .login-register-page {
        .page-intro {
            .rec-bottom {
                bottom: -200px !important;
            }
        }
        .login-container {
            width: 95%;
            padding: 50px 20px 70px;
            .title-box {
                h3 {
                    font-size: 30px;
                }
            }
            .form {
                width: 100%;
            }
        }
    }
}
