.shop-navbar{
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;
    z-index: 9;
    &.onscroll{
        position: fixed;
        background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
        box-shadow: 0px 4px 10px rgba(3, 4, 28, 0.1);
        -webkit-transition: all 0.3s 0s ease-out;
        -moz-transition: all 0.3s 0s ease-out;
        -ms-transition: all 0.3s 0s ease-out;
        -o-transition: all 0.3s 0s ease-out;
        transition: all ease-in-out .3s;
    }
    .container{
        width: 95%;
        .shop-navbar-container{
            display: flex;
            align-items: center;
            .mobile-menu{
                width: 60px;
                height: 60px;
                margin-left: auto;
                display: none;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.3);
                img{
                    width: 35px;
                    height: 35px;
                }
            }
            .nav-links{
                display: flex;
                width: 45%;
                transition: all ease-in-out .3s;
                .close-menu{
                    display: none;
                }
                li{
                    display: block;
                    margin-right: 50px;
                    a{
                        font-size: 16px;
                        font-weight: 500;
                        color: #FFF;
                        padding: 28px 0;
                        display: flex;
                        align-items: center;
                        line-height: 1;
                        img{
                            width: 25px;
                            height: 25px;
                            display: block;
                            margin-right: 10px;
                            display: none;
                        }
                        &.active{
                            color: $mainColor;
                        }
                    }
                }
                .login, .signup{
                    display: none;
                }
                .social-icons{
                    width: 100%;
                    display: none;
                    .icons-container{
                        width: 100%;
                        a{
                            display: block;
                            img{
                                width: 25px;
                                height: 25px;
                            }
                        }
                    }
                }
            }
            .shop-logo{
                width: 20%;
                text-align: center;
                img{
                    width: 50%;
                }
            }
            .icons-container{
                width: 35%;
                display: flex;
                justify-content: flex-end;
                .search{
                    width: 50px;
                    height: 50px;
                    padding: 5px 20px 5px 5px;
                    position: relative;
                    cursor: pointer;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                    &::after{
                        content: "";
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 2px;
                        height: 50%;
                        background-color: #FFF;
                    }
                }
                .login-register{
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    padding: 0 20px 0 0;
                    position: relative;
                    margin-left: 20px;
                    position: relative;
                    .user-menu{
                        position: absolute;
                        width: 100%;
                        top: 60px;
                        left: 0px;
                        z-index: -1;
                        padding: 15px;
                        display: none;
                        background-color: #333;
                        a{
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            margin-bottom: 15px;
                            padding: 5px;
                            font-weight: 500;
                            color: #FFF;
                            &:last-of-type{
                                margin-bottom: 0;
                            }
                            img{
                                margin-right: 5px;
                            }
                        }
                        &.show{
                            display: block !important;
                        }
                    }
                    img{
                        width: 25px;
                        height: 25px;
                    }
                    span{
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 24px;
                        padding-left: 12px;
                        color: #FFF;
                    }
                    &::after{
                        content: "";
                        width: 8px;
                        height: 8px;
                        border: 2px solid #FFF;
                        border-top-color: transparent;
                        border-left-color: transparent;
                        position: absolute;
                        right: 0;
                        top: 33%;
                        transform: rotate(45deg);
                    }
                }
                .cart{
                    a{
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: rgb(255, 255, 255);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 30px;
                        cursor: pointer;
                        position: relative;
                        span{
                            position: absolute;
                            top: 10px;
                            right: 0;
                            font-size: 12px;
                            font-weight: 500;
                            width: 15px;
                            height: 15px;
                            background-color: $mainColor;
                            color: #FFF;
                            border-radius: 50%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                    img{
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 480px){
    .shop-navbar{
        padding: 10px 0;
        .container{
            .shop-navbar-container{
                .nav-links{
                    width: 65%;
                    margin-right: -65%;
                    height: 100vh;
                    background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);
                    position: fixed;
                    right: 0;
                    top: 0;
                    padding: 80px 10px;
                    flex-direction: column;
                    li{
                        a{
                            img{
                                display: block;
                            }
                        }
                    }
                    .login, .signup{
                        display: flex;
                    }
                    .social-icons{
                        display: flex;
                        .icons-container{
                            display: flex;
                            justify-content: space-around;
                        }
                    }
                    .close-menu{
                        display: block;
                        background-color: $mainColor;
                        width: 30px;
                        height: 30px;
                        padding: 5px;
                        border-radius: 10px;
                        position: absolute;
                        left: 50%;
                        top: 40px;
                        transform: translateX(-50%);
                        cursor: pointer;
                    }
                    &.show-mobile-menu{
                        margin-right: 0;
                    }
                    // .user{
                    //     display: block;
                    //     .login, .signup{
                    //         display: flex;
                    //         align-items: center;
                    //         margin-bottom: 20px;
                    //         img{
                    //             width: 25px;
                    //             height: 25px;
                    //             display: block;
                    //             margin-right: 10px;
                    //         }
                    //         small{
                    //             font-size: 16px;
                    //             font-weight: 500;
                    //             color: #FFF;
                    //         }
                    //     }
                    // }
                }
                .shop-logo{
                    width: 35%;
                    img{
                        width: 70%;
                    }
                }
                .icons-container{
                    display: none;
                }
                .mobile-menu{
                    display: flex;
                }
            }
        }
    }
}