.about-page {
  .about-container {
    .about-intro {
      width: 100%;
      height: 100vh;
      background: url("../images/imgs/about-avatar.webp");
      background-size: cover;
      background-attachment: fixed;
      .container {
        .intro-info {
          position: absolute;
          top: 55%;
          left: 7.5%;
          transform: translateY(-50%);
          width: 85%;
          h3 {
            position: relative;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            padding-left: 30px;
            margin-bottom: 20px;
            letter-spacing: 0.05em;
            color: $mainColor;
            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              width: 15px;
              height: 1px;
              background-color: $mainColor;
            }
          }
          p {
            font-size: 48px;
            font-weight: 600;
            line-height: 1.3;
            text-wrap: balance;
            background: radial-gradient(
                ellipse farthest-corner at right bottom,
                #fedb37 0%,
                #fdb931 8%,
                #9f7928 30%,
                #8a6e2f 40%,
                transparent 80%
              ),
              radial-gradient(
                ellipse farthest-corner at left top,
                #ffffff 0%,
                #ffffac 8%,
                #d1b464 25%,
                #5d4a1f 62.5%,
                #5d4a1f 100%
              );
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            span {
              margin-left: 10px;
              background: radial-gradient(
                  ellipse farthest-corner at right bottom,
                  #fedb37 0%,
                  #fdb931 8%,
                  #9f7928 30%,
                  #8a6e2f 40%,
                  transparent 80%
                ),
                radial-gradient(
                  ellipse farthest-corner at left top,
                  #ffffff 0%,
                  #ffffac 8%,
                  #d1b464 25%,
                  #5d4a1f 62.5%,
                  #5d4a1f 100%
                );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            @media screen and (max-width: 480px) {
              font-size: 36px;
            }
          }
          .work-with {
            width: fit-content;
            height: fit-content;
            margin-top: 40px;
            position: relative;
            padding: 30px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid transparent;
            a {
              color: #fff;
              font-size: 12px;
              font-weight: 600;
              text-transform: uppercase;
              letter-spacing: 1.5px;
              padding: 0 30px;
              position: relative;
              transition: all ease-in-out 0.5s;
              &::before {
                content: "";
                width: 1px;
                height: 15px;
                position: absolute;
                top: -15px;
                left: 0;
                background-color: $mainColor;
                transition: all ease-in-out 0.5s;
              }
              &::after {
                content: "";
                width: 1px;
                height: 20px;
                position: absolute;
                bottom: -15px;
                right: 0;
                background-color: $mainColor;
                transition: all ease-in-out 0.5s;
              }
            }
            &::before {
              content: "";
              width: 80px;
              height: 1px;
              position: absolute;
              right: 0;
              top: 5px;
              background-color: $mainColor;
              transition: all ease-in-out 0.5s;
            }
            &::after {
              content: "";
              width: 40px;
              height: 1px;
              position: absolute;
              left: 0;
              bottom: 5px;
              background-color: $mainColor;
              transition: all ease-in-out 0.5s;
            }
            &:hover {
              a::before,
              a::after {
                height: 66px;
              }
              a::before {
                top: -24px;
              }
              a::after {
                bottom: -24px;
              }
              &::before,
              &::after {
                width: 168px;
              }
            }
          }
          &.ar {
            font-family: "Noto Kufi Arabic" !important;
            text-align: right;
            h3 {
              padding-left: unset;
              padding-right: 30px;
              &::before {
                left: unset;
                right: 0;
              }
            }
            p {
              font-size: 35px;
              line-height: 1.8;
              font-weight: bold;
              span {
                margin-left: 10px;
                margin-right: 10px;
              }
            }
            .work-with {
              margin-left: auto;
              &:hover {
                a::before,
                a::after {
                  height: 70px;
                }
                a::before {
                  top: -24px;
                }
                a::after {
                  bottom: -24px;
                }
                &::before,
                &::after {
                  width: 128px;
                }
              }
            }
          }
        }
      }
    }
  }
  .paragraphs-wraper {
    width: 100%;
    padding: 30px 0;
    background-image: url("../images/imgs/bg-body1.jpg");
    border-bottom: 1px solid #444;
    .paragraph {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .paragraph-img {
        width: 300px;
        height: 400px;
        border-radius: 5px;
        flex-shrink: 0;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 5px;
        }
      }
      .paragraph-content {
        flex-grow: 1;
        height: 100%;
        padding: 30px;
        h3, h5 {
          font-size: 35px;
          font-weight: 600;
          margin-bottom: 5px;
          background: radial-gradient(
              ellipse farthest-corner at right bottom,
              #fedb37 0%,
              #fdb931 8%,
              #9f7928 30%,
              #8a6e2f 40%,
              transparent 80%
            ),
            radial-gradient(
              ellipse farthest-corner at left top,
              #ffffff 0%,
              #ffffac 8%,
              #d1b464 25%,
              #5d4a1f 62.5%,
              #5d4a1f 100%
            );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        h5{
          font-size: 25px;
        }
        p {
          color: #b1b0b0;
          font-size: 18px;
          margin-bottom: 20px;
          span {
            color: $mainColor;
            margin-right: 5px;
          }
          &.gold{
          color: $mainColor;
          font-weight: 600;
          text-align: center;
          }
          &.green{
            background-color: #09af33;
            color: #fff;
            padding: 5px;
            border-radius: 5px;
          }
          &.red{
            background-color: #8f0808;
            color: #fff;
            padding: 5px;
            border-radius: 5px;
          }
        }
        .small-card-wraper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;
          .small-card {
            width: calc(95% / 3);
            min-height: 260px;
            background-color: #222;
            text-align: center;
            padding: 20px 10px 0 10px;
            border-radius: 10px;
            h4 {
              font-size: 20px;
              margin-bottom: 10px;
              background: radial-gradient(
                  ellipse farthest-corner at right bottom,
                  #fedb37 0%,
                  #fdb931 8%,
                  #9f7928 30%,
                  #8a6e2f 40%,
                  transparent 80%
                ),
                radial-gradient(
                  ellipse farthest-corner at left top,
                  #ffffff 0%,
                  #ffffac 8%,
                  #d1b464 25%,
                  #5d4a1f 62.5%,
                  #5d4a1f 100%
                );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
            p {
              font-size: 18px;
              color: #fff;
            }
          }
        }
        .img-full-width{
          width: 100%;
          height: auto;
          object-fit: cover;
          border-radius: 10px;
        }
      }
      .div-icons-wrapers {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-bottom: 20px;
        .div-icon {
          width: calc(90% / 2);
          min-height: 150px;
          margin-bottom: 20px;
          .icon-img{
            display: flex;
            align-items: center;
            img{
              width: 40px;
            }
            h4 {
              font-size: 25px;
              margin-left: 10px;
              background: radial-gradient(
                  ellipse farthest-corner at right bottom,
                  #fedb37 0%,
                  #fdb931 8%,
                  #9f7928 30%,
                  #8a6e2f 40%,
                  transparent 80%
                ),
                radial-gradient(
                  ellipse farthest-corner at left top,
                  #ffffff 0%,
                  #ffffac 8%,
                  #d1b464 25%,
                  #5d4a1f 62.5%,
                  #5d4a1f 100%
                );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          p {
            width: 90%;
            padding-left: 20px;
            font-size: 16px;
            color: #fff;
            margin-top: 10px;
          }
        }
      }
    }
    .cards-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      margin-bottom: 20px;
      .card {
        width: calc(95% / 4);
        height: 500px;
        text-align: center;
        border-radius: 10px;
        padding: 20px 0;
        background-color: #222;
        img {
          width: 150px;
          height: 150px;
          object-fit: cover;
          border-radius: 50%;
          border: 3px solid #fff;
        }
        h4 {
          font-size: 30px;
          margin-bottom: 10px;
          line-height: 1.2;
          background: radial-gradient(
              ellipse farthest-corner at right bottom,
              #fedb37 0%,
              #fdb931 8%,
              #9f7928 30%,
              #8a6e2f 40%,
              transparent 80%
            ),
            radial-gradient(
              ellipse farthest-corner at left top,
              #ffffff 0%,
              #ffffac 8%,
              #d1b464 25%,
              #5d4a1f 62.5%,
              #5d4a1f 100%
            );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        p {
          font-size: 20px;
          color: #fff;
          width: 95%;
          margin: auto;
        }
      }
    }
    @media screen and (max-width: 1023px) {
      .paragraph {
        flex-direction: column;
        justify-content: center;
        .paragraph-img{
          height: auto;
        }
        .paragraph-img,
        .paragraph-content {
          width: 100%;
          padding: 15px 5px;
          .small-card-wraper {
            flex-direction: column;
            align-items: center;
            .small-card {
              width: 90%;
              min-height: 200px;
              margin-bottom: 10px;
              h4 {
                font-size: 25px;
              }
            }
          }
        }
        &.right {
          .paragraph-img {
            order: 1;
          }
          .paragraph-content {
            order: 2;
          }
        }
        &.left{
          .paragraph-img {
            order: 2;
          }
          .paragraph-content {
            order: 1;
          }
        }
      }
      .cards-container {
        flex-direction: column;
        align-items: center;
        .card {
          width: 80%;
          margin-bottom: 20px;
        }
      }
    }
    .testimonials {
      background: transparent;
      padding: 0 50px 50px 50px;
      border: none;
      .title,
      p {
        display: none;
      }
      .parteners {
        margin-top: 0;
      }
    }
    &.ar{
      font-family: 'Noto Kufi Arabic' !important;
      .paragraph-content{
        text-align: right;
        h3{
          font-size: 35px;
          line-height: 1.9;
        }
        h5{
          direction: rtl;
          line-height: 1.9;
        }
        p{
          font-size: 18px;
          direction: rtl;
          line-height: 1.9;
          span{
            margin-left: 5px;
            margin-right: 0;
          }
        }
      }
    }
    @media screen and (max-width: 1023px){
      .div-icons-wrapers{
        flex-direction: column;
        align-items: center;
        .div-icon{
          width: 100% !important;
          .icon-img{
            width: 100%;
          }
          p{
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
