.order-details-page {
  padding-top: 180px;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);;
  .container {
    .order-wraper {
      width: 100%;
      h2 {
        font-size: 30px;
        color: #FFF;
        font-weight: 500;
        text-align: center;
      }
      .order-details-container {
        padding: 10px;
        margin: 30px 0;
        border-radius: 20px;
        background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
        display: flex;
        justify-content: space-between;
        .order-details {
          width: 45%;
          .user-info,
          .shipping-info,
          .order-status {
            margin-bottom: 30px;
            h3 {
              font-size: 20px;
              font-weight: 500;
              margin-bottom: 20px;
              padding: 5px 0 5px 10px;
              background: -webkit-linear-gradient(left, #a18755, #8f6B29);
              border-radius: 20px;
              color: #FFF;
            }
            ul {
              padding-left: 15px;
              li {
                margin-bottom: 10px;
                font-size: 16px;
                font-weight: 600;
                display: flex;
                color: #FFF;
                &:last-of-type {
                  margin-bottom: 0;
                }
                p {
                  margin-left: 10px;
                  font-size: 16px;
                  font-weight: 500;
                }
              }
              &.address-list{
                background-color: #121212;
                padding: 5px;
                border-radius: 5px;
                margin-top: 10px;
              }
            }
            .no-item{
              color: #FFF;
              padding-left: 10px;
            }
          }
        }
        .order-items {
          width: 53%;
          height: fit-content;
          border-radius: 20px;
          .order-products-wraper {
            width: 100%;
            .product-cart {
              display: flex;
              align-items: center;
              background: -webkit-linear-gradient(left, #a18755, #8f6B29);
              border-radius: 20px;
              padding: 10px;
              margin-bottom: 10px;
              width: 100%;
              .prodeuct-details {
                margin-left: 20px;
                ul {
                  li {
                    display: flex;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 10px;
                    color: #FFF;
                    &:last-of-type {
                      margin-bottom: 0;
                    }
                    p {
                      font-size: 16px;
                      text-align: center;
                      margin-left: 10px;
                      &.size {
                        text-transform: uppercase;
                      }
                    }
                  }
                }
              }
              .product-img {
                width: 80px;
                height: 100px;
                padding: 5px;
                background-color: #333;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  width: 90%;
                  border-radius: 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
