.intro {
    width: 100%;
    // min-height: 00px;
    padding-top: 40px;
    background-color: #000;
    background: url("../images/imgs/bg-body4.jpg");
    .social-media-links {
        position: absolute;
        top: 53%;
        right: -240px;
        z-index: 99;
        width: 40%;
        display: flex;
        justify-content: center;
        transform: rotate(-90deg);
        .social {
            width: 40px;
            margin-right: 70px;
            &:last-of-type {
                margin-right: 0;
            }
            a {
                display: inline-block;
                font-size: 12px;
                font-weight: 400;
                color: #fff;
                p {
                    display: none;
                }
                &:hover {
                    color: $mainColor;
                    span {
                        display: none;
                    }
                    p {
                        display: block;
                    }
                }
            }
        }
    }
    .swiper {
        width: 100%;
        height: 100%;
        .swiper-slide {
            width: 100%;
            height: 100%;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .container {
                height: 100%;
                display: flex;
                align-items: center;
                .info-wraper {
                    width: 50%;
                    height: fit-content;
                    h3 {
                        width: 100%;
                        font-size: 14px;
                        color: $mainColor;
                        text-transform: uppercase;
                        font-weight: 600;
                        position: relative;
                        padding-left: 25px;
                        letter-spacing: 1px;
                        &::before {
                            content: "";
                            width: 15px;
                            height: 1px;
                            background-color: $mainColor;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                    p {
                        font-size: 48px;
                        font-weight: 600;
                        color: #fff;
                        line-height: 1.3;
                        margin-top: 20px;
                        span {
                            color: $mainColor;
                            display: inline-block;
                            margin-left: 10px;
                        }
                    }
                    .work-with {
                        width: fit-content;
                        height: fit-content;
                        margin-top: 40px;
                        position: relative;
                        padding: 30px 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid transparent;
                        a {
                            color: #fff;
                            font-size: 12px;
                            font-weight: 600;
                            text-transform: uppercase;
                            letter-spacing: 1.5px;
                            padding: 0 30px;
                            position: relative;
                            transition: all ease-in-out 0.5s;
                            &::before {
                                content: "";
                                width: 1px;
                                height: 15px;
                                position: absolute;
                                top: -15px;
                                left: 0;
                                background-color: $mainColor;
                                transition: all ease-in-out 0.5s;
                            }
                            &::after {
                                content: "";
                                width: 1px;
                                height: 20px;
                                position: absolute;
                                bottom: -15px;
                                right: 0;
                                background-color: $mainColor;
                                transition: all ease-in-out 0.5s;
                            }
                        }
                        &::before {
                            content: "";
                            width: 80px;
                            height: 1px;
                            position: absolute;
                            right: 0;
                            top: 5px;
                            background-color: $mainColor;
                            transition: all ease-in-out 0.5s;
                        }
                        &::after {
                            content: "";
                            width: 40px;
                            height: 1px;
                            position: absolute;
                            left: 0;
                            bottom: 5px;
                            background-color: $mainColor;
                            transition: all ease-in-out 0.5s;
                        }
                        &:hover {
                            a::before,
                            a::after {
                                height: 66px;
                            }
                            a::before {
                                top: -24px;
                            }
                            a::after {
                                bottom: -24px;
                            }
                            &::before,
                            &::after {
                                width: 168px;
                            }
                        }
                    }
                    &.ar {
                        text-align: right;
                        font-family: "Noto Kufi Arabic" !important;
                        h3 {
                            text-align: right;
                            padding-right: 25px;
                            padding-left: 0;
                            &::before {
                                right: 0;
                                left: unset;
                            }
                        }
                        p {
                            line-height: 1.6;
                            word-spacing: -8px;
                            span {
                                margin-left: unset;
                                margin-right: 10px;
                            }
                        }
                        .work-with {
                            margin-left: auto;
                            &::before {
                                content: "";
                                width: 80px;
                                height: 1px;
                                position: absolute;
                                right: 0;
                                bottom: 5px;
                                background-color: $mainColor;
                                transition: all ease-in-out 0.5s;
                            }
                            &::after {
                                content: "";
                                width: 40px;
                                height: 1px;
                                position: absolute;
                                right: 0;
                                bottom: 5px;
                                background-color: $mainColor;
                                transition: all ease-in-out 0.5s;
                            }
                            &:hover {
                                a::before,
                                a::after {
                                    height: 70px;
                                }
                                a::before {
                                    top: -24px;
                                }
                                a::after {
                                    bottom: -24px;
                                }
                                &::before,
                                &::after {
                                    width: 128px;
                                }
                            }
                        }
                    }
                }
                .slide-img {
                    width: 50%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-top: 70px;
                    img {
                        width: 85%;
                        height: 90%;
                        object-fit: cover;
                    }
                }
            }
        }
        .swiper-pagination {
            width: fit-content;
            left: 7.5%;
            bottom: 50px;
            .swiper-pagination-bullet {
                width: 50px;
                height: 1px;
                border-radius: 0;
                margin: 0;
                background-color: #ccc;
                position: relative;
                &.swiper-pagination-bullet-active {
                    background-color: $mainColor;
                }
                &::before {
                    content: "01";
                    font-size: 12px;
                    font-weight: 600;
                    position: absolute;
                    top: -30px;
                    color: #fff;
                }
                &:nth-of-type(2)::before {
                    content: "02";
                }
                &:nth-of-type(3)::before {
                    content: "03";
                }
            }
        }
        &.ar {
            .swiper-pagination {
                right: 7.5%;
                left: unset;
            }
        }
    }
}

//global responsive
@media screen and (max-width: 1023px) {
    .intro {
        .social-media-links {
            display: none;
        }
        .swiper {
            .swiper-slide {
                padding-left: 10px;
                .container {
                    flex-direction: column-reverse;
                    .info-wraper {
                        width: 100%;
                        margin-top: 30px;
                        p {
                            font-size: 36px;
                        }
                        .work-with {
                            margin-bottom: 130px;
                        }
                    }
                    .slide-img {
                        width: 100%;
                    }
                    &.ar {
                        flex-direction: column;
                        .info-wraper {
                            p {
                                font-size: 34px;
                            }
                        }
                    }
                }
            }
            &.ar {
                .swiper-slide {
                    .overlay {
                        padding-left: unset;
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}

