.add-address-page{
    padding-top: 180px;
    background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
    h2{
        text-align: center;
        font-size: 36px;
        font-weight: 600;
        color: #FFF;
    }
    .add-address-containers{
        width: 90%;
        margin: auto;
        margin-top: 30px;
        background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
        padding: 20px;
        border-radius: 20px;
        .msg-wraper{
            width: 60%;
            padding: 15px 10px;
            text-align: center;
            justify-content: center;
            background-color: #75b798;
            display: none;
            margin: auto;
            margin-top: 20px;
            h5{
                margin-left: 5px;
                color: #051B11;
                font-size: 16px;
            }
            p{
                font-size: 16px;
            }
        }
        h3{
            text-align: center;
            color: #FFF;
            font-size: 24px;
        }
        form{
            width: 100%;
            margin-top: 30px;
            input{
                display: block;
                width: 80%;
                padding: 20px 10px;
                margin: auto;
                border-radius: 10px;
                background-color: #111;
                color: #FFF;
                font-size: 16px;
                border: none;
                outline: none;
                margin-bottom: 15px;
                &.submit{
                    background: -webkit-linear-gradient(left, #a18755, #8f6B29);
                    color: #FFF;                        
                    font-weight: 600;
                    font-size: 16px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }
        .adresses-wraper{
            width: 100%;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            .no-items{
                width: 100%;
                text-align: center;
                font-size: 18px;
                color: #FFF;
            }
            .address{
                display: flex;
                position: relative;
                width: 100%;
                align-items: center;
                padding: 10px;
                margin-bottom: 20px;
                border-radius: 20px;
                background: -webkit-linear-gradient(left, #a18755, #8f6B29);
                .trash-img{
                    width: 40px;
                    height: 40px;
                    padding: 5px;
                    cursor: pointer;
                    position: absolute;
                    right: 19px;
                    top: 10px;
                }
                .home-img{
                    width: 159px;
                    height: 150px;
                    padding: 10px;
                    display: block;
                    border-radius: 20px;
                    background-color: #333;
                }
                .address-details{
                    margin-left: 20px;
                    padding: 20px 0;
                    span{
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                        h5{
                            font-size: 16px;
                            font-weight: 600;
                            color: $headingColor;
                        }
                        p{
                            font-size: 16px;
                            font-weight: 500;
                            margin-left: 5px;
                            color: #FFF;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 576px){
    .add-address-page{
        .add-address-containers{
            width: 98%;
            .msg-wraper{
                width: 95%;
            }
            form{
                input{
                    width: 100%;
                }
            }
            .adresses-wraper{
                .address{
                    .home-img{
                        display: none;
                    }
                }
            }
        }
    }
}