.product {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border: 1px solid #eaeaea;
    border-radius: 20px;
    .product-img {
        display: block;
        width: 80%;
        height: 50%;
        a{
            display: block;
            width: 100%;
            height: 100%;
            text-align: center;
            img{
                width: 80%;
                object-fit: cover;
            }
        }
    }
    h3{
        a{
            display: block;
            font-size: 20px;
            font-weight: 600;
            line-height: 1;
            margin-bottom: 20px;
            margin-top: 40px;
            text-align: center;
            color: $headingColor;
            transition: all ease-in-out .3s;
            &:hover{
                color: $mainColor;
            }
        }
    }
    .price{
        display: flex;
        justify-content: center;
        p{
            font-size: 20px;
            display: block;
            font-weight: 600;
            color: $mainColor;
            line-height: 1;
            margin-bottom: 28px;
            margin-right: 15px;
            &.price-before{
                color: $textColor;
                text-decoration: line-through;
                opacity: .8;
            }
        }
    }
    .rating{
        display: flex;
        justify-content: center;
        img{
            display: block;
            width: 20px;
            height: 20px;
            margin-right: 5px;
            &:last-of-type{
                margin-right: 0;
            }
        }
    }
    .add-to-cart{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 28px;
        cursor: pointer;
        img{
            width: 45px;
            height: 45px;
            padding: 8px;
            border-radius: 50%;
            background-color: $headingColor;
        }
        span{
            padding: 17px 34px;
            border: 1px solid #EAEAEA;
            background: #F9F9F9;
            color: $headingColor;
            border-radius: 50px;
            font-size: 14px;
            font-weight: 600;
            margin-left: 10px;
            &:hover{
                background-color: $mainColor;
                color: #FFF;
                transition: all ease-in-out .3s;
            }
        }
        button, .edit{
            display: block;
            width: 45%;
            padding: 4px 0 3px;
            font-size: 16px;
            text-align: center;
            font-weight: 500;
            margin-right: 10px;
            color: #FFF;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            background-color: rgb(5, 139, 57);
            &.delete{
                margin-right: 0;
                background-color: rgb(185, 11, 11);
                padding: 7px 0 7px;
            }
        }
    }
    &:hover {
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
    }
}