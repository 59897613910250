@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;400;500;600;700;800&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

::-moz-selection {
  background-color: #8f6B29;
  color: #FFF;
}

::selection {
  background-color: #8f6B29;
  color: #FFF;
}

body {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
          appearance: textfield;
}

.container {
  width: 85%;
  margin: auto;
}
@media screen and (max-width: 480px) {
  .container {
    width: 95%;
  }
}

@media screen and (max-width: 480px) {
  .shop-page {
    padding-bottom: 50px;
  }
}

.navbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 18px 0;
  transition: all ease-in-out 0.3s;
}
.navbar::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  color: #fff;
  box-shadow: inset 0 0 0 1px;
  opacity: 0.2;
}
.navbar .container .nav-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar .container .nav-container .logo {
  width: 70px;
}
.navbar .container .nav-container .logo img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.navbar .container .nav-container .nav-wraper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 70%;
}
.navbar .container .nav-container .nav-wraper ul {
  width: 100%;
  text-align: right;
}
.navbar .container .nav-container .nav-wraper ul li {
  display: inline-block;
  margin-right: 15px;
  text-align: left;
}
.navbar .container .nav-container .nav-wraper ul li a {
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  padding: 10px;
}
.navbar .container .nav-container .nav-wraper ul li a.active {
  color: #8f6B29;
}
.navbar .container .nav-container .nav-wraper ul li a:hover {
  color: #8f6B29;
}
.navbar .container .nav-container .nav-wraper ul.menu-ar li a {
  font-family: "Noto Kufi Arabic" !important;
}
.navbar .container .nav-container .nav-wraper .nav-icons {
  display: flex;
  align-items: center;
}
.navbar .container .nav-container .nav-wraper .nav-icons .cart,
.navbar .container .nav-container .nav-wraper .nav-icons .search, .navbar .container .nav-container .nav-wraper .nav-icons .close-menu {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  position: relative;
}
.navbar .container .nav-container .nav-wraper .nav-icons .cart span,
.navbar .container .nav-container .nav-wraper .nav-icons .search span, .navbar .container .nav-container .nav-wraper .nav-icons .close-menu span {
  width: 12px;
  height: 12px;
  padding-top: 2px;
  border-radius: 50%;
  background-color: #8f6B29;
  color: #000;
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar .container .nav-container .nav-wraper .nav-icons .cart:last-of-type,
.navbar .container .nav-container .nav-wraper .nav-icons .search:last-of-type, .navbar .container .nav-container .nav-wraper .nav-icons .close-menu:last-of-type {
  margin-right: 0;
}
.navbar .container .nav-container .nav-wraper .nav-icons img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  cursor: pointer;
}
.navbar .container .nav-container .nav-wraper .nav-icons .mobile-menu {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  display: none;
}
.navbar .container .nav-container .nav-wraper .nav-icons .close-menu {
  display: none;
}

@media screen and (max-width: 991px) {
  .navbar .container .nav-container .nav-wraper ul {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 60%;
    padding: 80px 0 0 10px;
    background-color: #000;
    transition: all ease-in-out 0.3s;
  }
  .navbar .container .nav-container .nav-wraper ul li {
    display: block;
    margin-bottom: 20px;
  }
  .navbar .container .nav-container .nav-wraper ul.hide-menu {
    margin-right: -60%;
  }
  .navbar .container .nav-container .nav-wraper .nav-icons div {
    margin-left: 20px;
  }
  .navbar .container .nav-container .nav-wraper .nav-icons .mobile-menu {
    display: block;
  }
  .navbar .container .nav-container .nav-wraper .nav-icons .close-menu {
    display: block;
  }
}
.intro {
  width: 100%;
  padding-top: 40px;
  background-color: #000;
  background: url("../images/imgs/bg-body4.jpg");
}
.intro .social-media-links {
  position: absolute;
  top: 53%;
  right: -240px;
  z-index: 99;
  width: 40%;
  display: flex;
  justify-content: center;
  transform: rotate(-90deg);
}
.intro .social-media-links .social {
  width: 40px;
  margin-right: 70px;
}
.intro .social-media-links .social:last-of-type {
  margin-right: 0;
}
.intro .social-media-links .social a {
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
}
.intro .social-media-links .social a p {
  display: none;
}
.intro .social-media-links .social a:hover {
  color: #8f6B29;
}
.intro .social-media-links .social a:hover span {
  display: none;
}
.intro .social-media-links .social a:hover p {
  display: block;
}
.intro .swiper {
  width: 100%;
  height: 100%;
}
.intro .swiper .swiper-slide {
  width: 100%;
  height: 100%;
}
.intro .swiper .swiper-slide img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.intro .swiper .swiper-slide .container {
  height: 100%;
  display: flex;
  align-items: center;
}
.intro .swiper .swiper-slide .container .info-wraper {
  width: 50%;
  height: -moz-fit-content;
  height: fit-content;
}
.intro .swiper .swiper-slide .container .info-wraper h3 {
  width: 100%;
  font-size: 14px;
  color: #8f6B29;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  padding-left: 25px;
  letter-spacing: 1px;
}
.intro .swiper .swiper-slide .container .info-wraper h3::before {
  content: "";
  width: 15px;
  height: 1px;
  background-color: #8f6B29;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.intro .swiper .swiper-slide .container .info-wraper p {
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  line-height: 1.3;
  margin-top: 20px;
}
.intro .swiper .swiper-slide .container .info-wraper p span {
  color: #8f6B29;
  display: inline-block;
  margin-left: 10px;
}
.intro .swiper .swiper-slide .container .info-wraper .work-with {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 40px;
  position: relative;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
}
.intro .swiper .swiper-slide .container .info-wraper .work-with a {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 0 30px;
  position: relative;
  transition: all ease-in-out 0.5s;
}
.intro .swiper .swiper-slide .container .info-wraper .work-with a::before {
  content: "";
  width: 1px;
  height: 15px;
  position: absolute;
  top: -15px;
  left: 0;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.intro .swiper .swiper-slide .container .info-wraper .work-with a::after {
  content: "";
  width: 1px;
  height: 20px;
  position: absolute;
  bottom: -15px;
  right: 0;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.intro .swiper .swiper-slide .container .info-wraper .work-with::before {
  content: "";
  width: 80px;
  height: 1px;
  position: absolute;
  right: 0;
  top: 5px;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.intro .swiper .swiper-slide .container .info-wraper .work-with::after {
  content: "";
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 5px;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.intro .swiper .swiper-slide .container .info-wraper .work-with:hover a::before,
.intro .swiper .swiper-slide .container .info-wraper .work-with:hover a::after {
  height: 66px;
}
.intro .swiper .swiper-slide .container .info-wraper .work-with:hover a::before {
  top: -24px;
}
.intro .swiper .swiper-slide .container .info-wraper .work-with:hover a::after {
  bottom: -24px;
}
.intro .swiper .swiper-slide .container .info-wraper .work-with:hover::before, .intro .swiper .swiper-slide .container .info-wraper .work-with:hover::after {
  width: 168px;
}
.intro .swiper .swiper-slide .container .info-wraper.ar {
  text-align: right;
  font-family: "Noto Kufi Arabic" !important;
}
.intro .swiper .swiper-slide .container .info-wraper.ar h3 {
  text-align: right;
  padding-right: 25px;
  padding-left: 0;
}
.intro .swiper .swiper-slide .container .info-wraper.ar h3::before {
  right: 0;
  left: unset;
}
.intro .swiper .swiper-slide .container .info-wraper.ar p {
  line-height: 1.6;
  word-spacing: -8px;
}
.intro .swiper .swiper-slide .container .info-wraper.ar p span {
  margin-left: unset;
  margin-right: 10px;
}
.intro .swiper .swiper-slide .container .info-wraper.ar .work-with {
  margin-left: auto;
}
.intro .swiper .swiper-slide .container .info-wraper.ar .work-with::before {
  content: "";
  width: 80px;
  height: 1px;
  position: absolute;
  right: 0;
  bottom: 5px;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.intro .swiper .swiper-slide .container .info-wraper.ar .work-with::after {
  content: "";
  width: 40px;
  height: 1px;
  position: absolute;
  right: 0;
  bottom: 5px;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.intro .swiper .swiper-slide .container .info-wraper.ar .work-with:hover a::before,
.intro .swiper .swiper-slide .container .info-wraper.ar .work-with:hover a::after {
  height: 70px;
}
.intro .swiper .swiper-slide .container .info-wraper.ar .work-with:hover a::before {
  top: -24px;
}
.intro .swiper .swiper-slide .container .info-wraper.ar .work-with:hover a::after {
  bottom: -24px;
}
.intro .swiper .swiper-slide .container .info-wraper.ar .work-with:hover::before, .intro .swiper .swiper-slide .container .info-wraper.ar .work-with:hover::after {
  width: 128px;
}
.intro .swiper .swiper-slide .container .slide-img {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 70px;
}
.intro .swiper .swiper-slide .container .slide-img img {
  width: 85%;
  height: 90%;
  -o-object-fit: cover;
     object-fit: cover;
}
.intro .swiper .swiper-pagination {
  width: -moz-fit-content;
  width: fit-content;
  left: 7.5%;
  bottom: 50px;
}
.intro .swiper .swiper-pagination .swiper-pagination-bullet {
  width: 50px;
  height: 1px;
  border-radius: 0;
  margin: 0;
  background-color: #ccc;
  position: relative;
}
.intro .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #8f6B29;
}
.intro .swiper .swiper-pagination .swiper-pagination-bullet::before {
  content: "01";
  font-size: 12px;
  font-weight: 600;
  position: absolute;
  top: -30px;
  color: #fff;
}
.intro .swiper .swiper-pagination .swiper-pagination-bullet:nth-of-type(2)::before {
  content: "02";
}
.intro .swiper .swiper-pagination .swiper-pagination-bullet:nth-of-type(3)::before {
  content: "03";
}
.intro .swiper.ar .swiper-pagination {
  right: 7.5%;
  left: unset;
}

@media screen and (max-width: 1023px) {
  .intro .social-media-links {
    display: none;
  }
  .intro .swiper .swiper-slide {
    padding-left: 10px;
  }
  .intro .swiper .swiper-slide .container {
    flex-direction: column-reverse;
  }
  .intro .swiper .swiper-slide .container .info-wraper {
    width: 100%;
    margin-top: 30px;
  }
  .intro .swiper .swiper-slide .container .info-wraper p {
    font-size: 36px;
  }
  .intro .swiper .swiper-slide .container .info-wraper .work-with {
    margin-bottom: 130px;
  }
  .intro .swiper .swiper-slide .container .slide-img {
    width: 100%;
  }
  .intro .swiper .swiper-slide .container.ar {
    flex-direction: column;
  }
  .intro .swiper .swiper-slide .container.ar .info-wraper p {
    font-size: 34px;
  }
  .intro .swiper.ar .swiper-slide .overlay {
    padding-left: unset;
    padding-right: 10px;
  }
}
.our-products {
  width: 100%;
  background-image: url("../images/imgs/bg-body4.jpg");
  border-bottom: 1px solid #444;
  padding: 80px 0 40px 0;
}
.our-products .container .our-products-wraper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.our-products .container .our-products-wraper h5 {
  color: #8f6B29;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-bottom: 14px;
}
.our-products .container .our-products-wraper .title {
  font-size: 36px;
  font-weight: 600;
  color: #FFF;
}
.our-products .container .our-products-wraper .products-container {
  width: 100%;
  height: 435px;
  margin-top: 50px;
}
.our-products .container .our-products-wraper .products-container .swiper {
  width: 100%;
  height: 100%;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-button-next {
  width: 40px;
  top: 20px;
  right: 180px;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-button-next::after {
  content: "";
  width: 8px;
  height: 5px;
  border: 2px solid #8f6B29;
  border-left-color: transparent;
  border-top-color: transparent;
  transform: rotate(-45deg);
  right: 0;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-button-next::before {
  content: "";
  width: 40px;
  height: 2px;
  right: -5px;
  background-color: #8f6B29;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-button-prev {
  width: 40px;
  top: 20px;
  left: unset;
  right: 240px;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-button-prev::before {
  content: "";
  width: 8px;
  height: 5px;
  border: 2px solid #8f6B29;
  border-right-color: transparent;
  border-bottom-color: transparent;
  transform: rotate(-45deg);
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-button-prev::after {
  content: "";
  width: 50px;
  height: 2px;
  left: -5px;
  background-color: #8f6B29;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-slide {
  width: 100%;
  height: 100%;
  display: flex;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-slide .slide-number {
  width: 20%;
  height: 100%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-slide .slide-number p {
  width: 100%;
  font-size: 60px;
  font-weight: 100;
  text-align: left;
  color: #FFF;
  position: relative;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-slide .slide-number p:first-of-type::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 50%;
  background-color: #8f6B29;
  width: 25px;
  height: 1px;
  transform: rotate(-20deg);
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-slide .slide-number p:last-of-type {
  text-align: right;
  opacity: 0.2;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-slide .product-img {
  width: 50%;
  height: 100%;
  border: 1px solid #444;
  overflow: hidden;
  background: linear-gradient(to bottom, #111 0%, #333 100%);
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-slide .product-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-slide .product-info {
  width: 30%;
  height: 100%;
  margin-top: 70px;
  margin-left: -40px;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-slide .product-info h3 {
  font-size: 36px;
  font-weight: 600;
  color: #FFF;
  line-height: 1;
}
.our-products .container .our-products-wraper .products-container .swiper .swiper-slide .product-info small {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  color: #8f6B29;
  margin-top: 30px;
}
.our-products .container .our-products-wraper.ar {
  font-family: "Noto Kufi Arabic" !important;
}
.our-products .container .our-products-wraper.ar .product-info h3 {
  line-height: 1.3 !important;
  font-size: 30px !important;
}

@media screen and (max-width: 480px) {
  .our-products {
    padding: 80px 0;
  }
  .our-products .container .our-products-wraper .products-container {
    height: -moz-fit-content;
    height: fit-content;
  }
  .our-products .container .our-products-wraper .products-container .swiper {
    width: 95%;
  }
  .our-products .container .our-products-wraper .products-container .swiper .swiper-button-next, .our-products .container .our-products-wraper .products-container .swiper .swiper-button-prev {
    display: none;
  }
  .our-products .container .our-products-wraper .products-container .swiper .swiper-slide {
    flex-direction: column;
  }
  .our-products .container .our-products-wraper .products-container .swiper .swiper-slide .slide-number {
    display: none;
  }
  .our-products .container .our-products-wraper .products-container .swiper .swiper-slide .product-img {
    width: 100%;
  }
  .our-products .container .our-products-wraper .products-container .swiper .swiper-slide .product-info {
    width: 100%;
    margin: 0;
    margin-top: 40px;
  }
  .our-products .container .our-products-wraper .products-container .swiper .swiper-slide .product-info h3 {
    font-size: 30px;
  }
  .our-products .container .our-products-wraper .products-container .swiper .swiper-slide .product-info small {
    margin-top: 10px;
    text-align: left;
  }
}
.info {
  width: 100%;
  padding: 130px 0 100px 0;
  background-image: url("../images/imgs/bg-body1.jpg");
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  position: relative;
}
.info .container .info-wraper {
  width: 100%;
  display: flex;
  align-items: center;
}
.info .container .info-wraper .details-container {
  width: 75%;
  height: auto;
}
.info .container .info-wraper .details-container .details {
  width: 100%;
}
.info .container .info-wraper .details-container .details h3 {
  display: none;
  margin-bottom: 35px;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.info .container .info-wraper .details-container .details h3 small {
  font-size: 14px;
  font-weight: 300;
  color: #b1b0b0;
  margin-right: 15px;
}
.info .container .info-wraper .details-container .details h3.active {
  color: #8f6B29;
}
.info .container .info-wraper .details-container .details h3.active small {
  color: #fff;
}
.info .container .info-wraper .details-container .details .inner-details {
  display: flex;
}
.info .container .info-wraper .details-container .details .inner-details .img {
  width: 260px;
  height: 355px;
  margin-right: 50px;
  border: 1px solid #444;
  flex-shrink: 0;
  margin-top: 20px;
}
.info .container .info-wraper .details-container .details .inner-details .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.info .container .info-wraper .details-container .details .inner-details .inner-info {
  padding-top: 20px;
}
.info .container .info-wraper .details-container .details .inner-details .inner-info h5 {
  padding-left: 60px;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  letter-spacing: 1px;
  position: relative;
  color: #8f6B29;
}
.info .container .info-wraper .details-container .details .inner-details .inner-info h5::before {
  content: "";
  width: 30px;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  background-color: #8f6B29;
}
.info .container .info-wraper .details-container .details .inner-details .inner-info h2 {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.info .container .info-wraper .details-container .details .inner-details .inner-info h2 span {
  margin-right: 20px;
  font-weight: bold;
  font-size: 1.5em;
  opacity: 0.1;
}
.info .container .info-wraper .details-container .details .inner-details .inner-info p {
  font-size: 16px;
  font-weight: 300;
  color: #FEFEFE;
  line-height: 1.7;
  margin-bottom: 10px;
}
.info .container .info-wraper .details-container .details .inner-details .inner-info p span {
  color: #8f6B29;
  font-weight: 500;
}
.info .container .info-wraper .details-container .details .inner-details .inner-info p:last-of-type {
  margin-bottom: 0;
}
.info .container .info-wraper .details-container .details .inner-details .inner-info .read-more {
  display: block;
  width: 100px;
  margin-top: 30px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 0 10px;
  border: none;
  border-left: 1px solid #8f6B29;
  border-right: 1px solid #8f6B29;
  letter-spacing: 0.1em;
  background-color: transparent;
}
.info .container .info-wraper .details-container .details.ar {
  font-family: "Noto Kufi Arabic" !important;
  direction: rtl;
}
.info .container .info-wraper .details-container .details.ar .inner-details .img {
  margin-left: 50px;
}
.info .container .info-wraper .details-container .details.ar .inner-details .inner-info h5 {
  padding-right: 40px;
}
.info .container .info-wraper .details-container .details.ar .inner-details .inner-info h5::before {
  right: 0;
}
.info .container .info-wraper .details-container .details.ar .inner-details .inner-info h2 {
  margin-bottom: 5px;
}
.info .container .info-wraper .details-container .details.ar .inner-details .inner-info h2 span {
  margin-left: 20px;
  font-weight: bold;
  font-size: 1.5em;
  opacity: 0.1;
}
.info .container .info-wraper .details-container .details.ar .inner-details .inner-info p {
  font-size: 16px;
  font-weight: 300;
  color: #FEFEFE;
  line-height: 1.7;
  margin-bottom: 10px;
}
.info .container .info-wraper .details-container .details.ar .inner-details .inner-info p span {
  color: #8f6B29;
  font-weight: 500;
  margin-left: 5px;
}
.info .container .info-wraper .details-container .details.ar .inner-details .inner-info p:last-of-type {
  margin-bottom: 0;
}
.info .container .info-wraper .details-container .details.ar .inner-details .inner-info .read-more {
  display: block;
  width: 100px;
  margin-top: 30px;
  margin-right: auto;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  padding: 0 10px;
  border: none;
  border-left: 1px solid #8f6B29;
  border-right: 1px solid #8f6B29;
  letter-spacing: 0.1em;
  background-color: transparent;
}
.info .container .info-wraper .links {
  width: 25%;
  padding-left: 50px;
}
.info .container .info-wraper .links ul {
  width: 100%;
  height: 100%;
}
.info .container .info-wraper .links ul li {
  margin-bottom: 35px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.info .container .info-wraper .links ul li:hover, .info .container .info-wraper .links ul li.active {
  color: #8f6B29;
}
.info .container .info-wraper .links ul li:hover small, .info .container .info-wraper .links ul li.active small {
  color: #fff;
}
.info .container .info-wraper .links ul li small {
  font-size: 14px;
  font-weight: 300;
  color: #b1b0b0;
  margin-right: 15px;
}
.info .container .info-wraper .links ul.ar {
  font-family: "Noto Kufi Arabic" !important;
  direction: rtl;
  padding-right: 50px;
}
.info .container .info-wraper .links ul.ar li small {
  margin-left: 15px;
}
.info .container .info-wraper .links.ar {
  padding-left: unset;
}

@media screen and (max-width: 576px) {
  .info .container .info-wraper {
    padding-left: 10px;
  }
  .info .container .info-wraper .details-container {
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
  }
  .info .container .info-wraper .details-container .details {
    margin-bottom: 30px;
  }
  .info .container .info-wraper .details-container .details h3 {
    display: flex;
    margin-bottom: 10px;
  }
  .info .container .info-wraper .details-container .details .inner-details {
    flex-direction: column;
  }
  .info .container .info-wraper .details-container .details .inner-details .img {
    width: 85%;
    height: 300px;
    margin-right: 0;
  }
  .info .container .info-wraper .details-container .details .inner-details .inner-info h2 {
    font-size: 36px;
  }
  .info .container .info-wraper .details-container .details .inner-details .inner-info .read-more {
    width: 120px;
    text-align: center;
    font-size: 14px;
  }
  .info .container .info-wraper .details-container .details.ar h3 small {
    margin-right: unset;
    margin-left: 15px;
  }
  .info .container .info-wraper .links {
    display: none;
  }
}
@media screen and (max-width: 577px) {
  .info .container .info-wraper {
    padding-left: 10px;
  }
  .info .container .info-wraper .details-container {
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
  }
  .info .container .info-wraper .details-container .details {
    margin-bottom: 30px;
  }
  .info .container .info-wraper .details-container .details h3 {
    display: flex;
    margin-bottom: 10px;
  }
  .info .container .info-wraper .details-container .details .inner-details .img {
    width: 85%;
    height: 300px;
    margin-right: 0;
  }
  .info .container .info-wraper .details-container .details .inner-details .inner-info .read-more {
    width: 120px;
    text-align: center;
    font-size: 14px;
  }
  .info .container .info-wraper .links {
    display: none;
  }
}
.testimonials {
  padding: 50px;
  background: url("../images/imgs/bg-body1.jpg");
  border-bottom: 1px solid #444;
}
.testimonials .container .testimonials-container {
  width: 100%;
}
.testimonials .container .testimonials-container .title {
  text-align: center;
  position: relative;
  padding-bottom: 15px;
}
.testimonials .container .testimonials-container .title h5 {
  margin-bottom: 13px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #8f6B29;
  text-transform: uppercase;
}
.testimonials .container .testimonials-container .title h3 {
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}
.testimonials .container .testimonials-container .title::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 1px;
  background-color: #8f6B29;
}
.testimonials .container .testimonials-container .testimonials-wraper {
  width: 100%;
  margin-top: 30px;
}
.testimonials .container .testimonials-container .testimonials-wraper .testimonial {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonials .container .testimonials-container .testimonials-wraper .testimonial .avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: relative;
}
.testimonials .container .testimonials-container .testimonials-wraper .testimonial .avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
}
.testimonials .container .testimonials-container .testimonials-wraper .testimonial .avatar::before {
  content: "";
  width: 5px;
  height: 5px;
  border: 1px solid #8f6B29;
  position: absolute;
  top: 50%;
  left: -50px;
  transform: translateY(-50%);
  transform: rotate(45deg);
  border-top-color: transparent;
  border-right-color: transparent;
}
.testimonials .container .testimonials-container .testimonials-wraper .testimonial .avatar::after {
  content: "";
  width: 5px;
  height: 5px;
  border: 1px solid #8f6B29;
  position: absolute;
  top: 50%;
  right: -50px;
  transform: translateY(-50%);
  transform: rotate(45deg);
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.testimonials .container .testimonials-container .testimonials-wraper .testimonial .testimonial-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonials .container .testimonials-container .testimonials-wraper .testimonial .testimonial-details p {
  width: 75%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 50px;
  text-align: center;
  font-size: 18px;
  font-style: italic;
  line-height: 1.6;
  color: #b1b0b0;
  position: relative;
}
.testimonials .container .testimonials-container .testimonials-wraper .testimonial .testimonial-details p::before {
  content: "“";
  position: absolute;
  top: 0;
  left: 50%;
  font-size: 100px;
  font-weight: bold;
  font-style: normal;
  line-height: 1;
  pointer-events: none;
  width: 1em;
  margin-left: -0.5em;
  color: #8f6B29;
}
.testimonials .container .testimonials-container.ar {
  font-family: "Noto Kufi Arabic" !important;
}
.testimonials .parteners {
  margin-top: 30px;
}
.testimonials .parteners .swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.testimonials .parteners .swiper .swiper-slide img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  filter: grayscale(100%);
}
.testimonials .parteners .swiper .swiper-slide img:hover {
  filter: grayscale(0);
  transition: all ease-in-out 0.2s;
}
.testimonials .last-p {
  width: 90%;
  margin: auto;
  margin-top: 20px;
  padding-top: 50px;
  text-align: center;
  font-size: 18px;
  font-style: italic;
  line-height: 1.6;
  color: #b1b0b0;
}

@media screen and (max-width: 480px) {
  .testimonials {
    padding: 50px 0;
  }
  .testimonials .container {
    width: 95%;
  }
  .testimonials .container .testimonials-container .testimonials-wraper .testimonial .testimonial-details p {
    width: 95%;
  }
  .testimonials .parteners {
    margin-top: 20px;
  }
  .testimonials .parteners .swiper .swiper-slide img {
    width: 50%;
  }
  .testimonials .last-p {
    width: 95%;
  }
}
.footer {
  padding: 60px 0 30px 0;
  background-color: #1a1a1a;
}
.footer .container .footer-container {
  display: flex;
  justify-content: space-between;
}
.footer .container .footer-container .info-footer {
  width: 31.6666666667%;
}
.footer .container .footer-container .info-footer .logo-footer {
  margin-bottom: 10px;
}
.footer .container .footer-container .info-footer .logo-footer img {
  width: 60px;
}
.footer .container .footer-container .info-footer .info-footer-wraper p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  max-width: 255px;
  color: #cacaca;
}
.footer .container .footer-container .info-footer .copyright {
  margin-top: 25px;
}
.footer .container .footer-container .info-footer .copyright p {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  color: #989796;
}
.footer .container .footer-container .info-footer .copyright p a {
  color: #989796;
}
.footer .container .footer-container .info-footer .copyright p a:hover {
  color: #FFF;
}
.footer .container .footer-container .links-footer {
  width: 31.6666666667%;
}
.footer .container .footer-container .links-footer h6 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  color: #FFF;
}
.footer .container .footer-container .links-footer .links-footer-wraper {
  display: flex;
}
.footer .container .footer-container .links-footer .links-footer-wraper ul {
  list-style: none;
}
.footer .container .footer-container .links-footer .links-footer-wraper ul li {
  padding: 10px 0;
}
.footer .container .footer-container .links-footer .links-footer-wraper ul li a {
  position: relative;
  color: #FFF;
  display: block;
  padding-left: 25px;
  line-height: 1.2em;
  text-decoration: none;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.footer .container .footer-container .links-footer .links-footer-wraper ul li a:hover {
  color: #8f6B29;
}
.footer .container .footer-container .links-footer .links-footer-wraper ul li a::before {
  content: "";
  width: 4px;
  height: 4px;
  border: 1px solid #8f6B29;
  border-bottom-color: transparent;
  border-left-color: transparent;
  position: absolute;
  left: 0;
  top: 3px;
  transform: rotate(45deg);
}
.footer .container .footer-container .links-footer .links-footer-wraper ul:last-of-type {
  margin-left: 50px;
}
.footer .container .footer-container .contacts-footer {
  width: 31.6666666667%;
}
.footer .container .footer-container .contacts-footer h6 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
  color: #FFF;
}
.footer .container .footer-container .contacts-footer .contacts-footer-wraper {
  list-style: none;
}
.footer .container .footer-container .contacts-footer .contacts-footer-wraper li {
  margin-bottom: 10px;
  padding: 10px 0;
}
.footer .container .footer-container .contacts-footer .contacts-footer-wraper li a {
  display: flex;
  font-size: 14px;
  font-weight: 300;
  color: #FFF;
}
.footer .container .footer-container .contacts-footer .contacts-footer-wraper li a img {
  width: 22px;
  display: block;
  margin-right: 25px;
  opacity: 0.9;
}
.footer.ar {
  font-family: "Noto Kufi Arabic" !important;
}

@media screen and (max-width: 480px) {
  .footer .container .footer-container {
    flex-direction: column;
  }
  .footer .container .footer-container .info-footer, .footer .container .footer-container .links-footer, .footer .container .footer-container .contacts-footer {
    width: 100%;
    margin-bottom: 20px;
  }
  .footer .container .footer-container .info-footer.contacts-footer, .footer .container .footer-container .links-footer.contacts-footer, .footer .container .footer-container .contacts-footer.contacts-footer {
    margin-bottom: 0;
  }
}
.about-page .about-container .about-intro {
  width: 100%;
  height: 100vh;
  background: url("../images/imgs/about-avatar.webp");
  background-size: cover;
  background-attachment: fixed;
}
.about-page .about-container .about-intro .container .intro-info {
  position: absolute;
  top: 55%;
  left: 7.5%;
  transform: translateY(-50%);
  width: 85%;
}
.about-page .about-container .about-intro .container .intro-info h3 {
  position: relative;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding-left: 30px;
  margin-bottom: 20px;
  letter-spacing: 0.05em;
  color: #8f6B29;
}
.about-page .about-container .about-intro .container .intro-info h3::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 15px;
  height: 1px;
  background-color: #8f6B29;
}
.about-page .about-container .about-intro .container .intro-info p {
  font-size: 48px;
  font-weight: 600;
  line-height: 1.3;
  text-wrap: balance;
  background: radial-gradient(ellipse farthest-corner at right bottom, #fedb37 0%, #fdb931 8%, #9f7928 30%, #8a6e2f 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #ffffff 0%, #ffffac 8%, #d1b464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-page .about-container .about-intro .container .intro-info p span {
  margin-left: 10px;
  background: radial-gradient(ellipse farthest-corner at right bottom, #fedb37 0%, #fdb931 8%, #9f7928 30%, #8a6e2f 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #ffffff 0%, #ffffac 8%, #d1b464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media screen and (max-width: 480px) {
  .about-page .about-container .about-intro .container .intro-info p {
    font-size: 36px;
  }
}
.about-page .about-container .about-intro .container .intro-info .work-with {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 40px;
  position: relative;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
}
.about-page .about-container .about-intro .container .intro-info .work-with a {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 0 30px;
  position: relative;
  transition: all ease-in-out 0.5s;
}
.about-page .about-container .about-intro .container .intro-info .work-with a::before {
  content: "";
  width: 1px;
  height: 15px;
  position: absolute;
  top: -15px;
  left: 0;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.about-page .about-container .about-intro .container .intro-info .work-with a::after {
  content: "";
  width: 1px;
  height: 20px;
  position: absolute;
  bottom: -15px;
  right: 0;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.about-page .about-container .about-intro .container .intro-info .work-with::before {
  content: "";
  width: 80px;
  height: 1px;
  position: absolute;
  right: 0;
  top: 5px;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.about-page .about-container .about-intro .container .intro-info .work-with::after {
  content: "";
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 5px;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.about-page .about-container .about-intro .container .intro-info .work-with:hover a::before,
.about-page .about-container .about-intro .container .intro-info .work-with:hover a::after {
  height: 66px;
}
.about-page .about-container .about-intro .container .intro-info .work-with:hover a::before {
  top: -24px;
}
.about-page .about-container .about-intro .container .intro-info .work-with:hover a::after {
  bottom: -24px;
}
.about-page .about-container .about-intro .container .intro-info .work-with:hover::before, .about-page .about-container .about-intro .container .intro-info .work-with:hover::after {
  width: 168px;
}
.about-page .about-container .about-intro .container .intro-info.ar {
  font-family: "Noto Kufi Arabic" !important;
  text-align: right;
}
.about-page .about-container .about-intro .container .intro-info.ar h3 {
  padding-left: unset;
  padding-right: 30px;
}
.about-page .about-container .about-intro .container .intro-info.ar h3::before {
  left: unset;
  right: 0;
}
.about-page .about-container .about-intro .container .intro-info.ar p {
  font-size: 35px;
  line-height: 1.8;
  font-weight: bold;
}
.about-page .about-container .about-intro .container .intro-info.ar p span {
  margin-left: 10px;
  margin-right: 10px;
}
.about-page .about-container .about-intro .container .intro-info.ar .work-with {
  margin-left: auto;
}
.about-page .about-container .about-intro .container .intro-info.ar .work-with:hover a::before,
.about-page .about-container .about-intro .container .intro-info.ar .work-with:hover a::after {
  height: 70px;
}
.about-page .about-container .about-intro .container .intro-info.ar .work-with:hover a::before {
  top: -24px;
}
.about-page .about-container .about-intro .container .intro-info.ar .work-with:hover a::after {
  bottom: -24px;
}
.about-page .about-container .about-intro .container .intro-info.ar .work-with:hover::before, .about-page .about-container .about-intro .container .intro-info.ar .work-with:hover::after {
  width: 128px;
}
.about-page .paragraphs-wraper {
  width: 100%;
  padding: 30px 0;
  background-image: url("../images/imgs/bg-body1.jpg");
  border-bottom: 1px solid #444;
}
.about-page .paragraphs-wraper .paragraph {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.about-page .paragraphs-wraper .paragraph .paragraph-img {
  width: 300px;
  height: 400px;
  border-radius: 5px;
  flex-shrink: 0;
}
.about-page .paragraphs-wraper .paragraph .paragraph-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content {
  flex-grow: 1;
  height: 100%;
  padding: 30px;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content h3, .about-page .paragraphs-wraper .paragraph .paragraph-content h5 {
  font-size: 35px;
  font-weight: 600;
  margin-bottom: 5px;
  background: radial-gradient(ellipse farthest-corner at right bottom, #fedb37 0%, #fdb931 8%, #9f7928 30%, #8a6e2f 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #ffffff 0%, #ffffac 8%, #d1b464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content h5 {
  font-size: 25px;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content p {
  color: #b1b0b0;
  font-size: 18px;
  margin-bottom: 20px;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content p span {
  color: #8f6B29;
  margin-right: 5px;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content p.gold {
  color: #8f6B29;
  font-weight: 600;
  text-align: center;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content p.green {
  background-color: #09af33;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content p.red {
  background-color: #8f0808;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content .small-card-wraper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content .small-card-wraper .small-card {
  width: 31.6666666667%;
  min-height: 260px;
  background-color: #222;
  text-align: center;
  padding: 20px 10px 0 10px;
  border-radius: 10px;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content .small-card-wraper .small-card h4 {
  font-size: 20px;
  margin-bottom: 10px;
  background: radial-gradient(ellipse farthest-corner at right bottom, #fedb37 0%, #fdb931 8%, #9f7928 30%, #8a6e2f 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #ffffff 0%, #ffffac 8%, #d1b464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content .small-card-wraper .small-card p {
  font-size: 18px;
  color: #fff;
}
.about-page .paragraphs-wraper .paragraph .paragraph-content .img-full-width {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.about-page .paragraphs-wraper .paragraph .div-icons-wrapers {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}
.about-page .paragraphs-wraper .paragraph .div-icons-wrapers .div-icon {
  width: 45%;
  min-height: 150px;
  margin-bottom: 20px;
}
.about-page .paragraphs-wraper .paragraph .div-icons-wrapers .div-icon .icon-img {
  display: flex;
  align-items: center;
}
.about-page .paragraphs-wraper .paragraph .div-icons-wrapers .div-icon .icon-img img {
  width: 40px;
}
.about-page .paragraphs-wraper .paragraph .div-icons-wrapers .div-icon .icon-img h4 {
  font-size: 25px;
  margin-left: 10px;
  background: radial-gradient(ellipse farthest-corner at right bottom, #fedb37 0%, #fdb931 8%, #9f7928 30%, #8a6e2f 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #ffffff 0%, #ffffac 8%, #d1b464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-page .paragraphs-wraper .paragraph .div-icons-wrapers .div-icon p {
  width: 90%;
  padding-left: 20px;
  font-size: 16px;
  color: #fff;
  margin-top: 10px;
}
.about-page .paragraphs-wraper .cards-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  margin-bottom: 20px;
}
.about-page .paragraphs-wraper .cards-container .card {
  width: 23.75%;
  height: 500px;
  text-align: center;
  border-radius: 10px;
  padding: 20px 0;
  background-color: #222;
}
.about-page .paragraphs-wraper .cards-container .card img {
  width: 150px;
  height: 150px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  border: 3px solid #fff;
}
.about-page .paragraphs-wraper .cards-container .card h4 {
  font-size: 30px;
  margin-bottom: 10px;
  line-height: 1.2;
  background: radial-gradient(ellipse farthest-corner at right bottom, #fedb37 0%, #fdb931 8%, #9f7928 30%, #8a6e2f 40%, transparent 80%), radial-gradient(ellipse farthest-corner at left top, #ffffff 0%, #ffffac 8%, #d1b464 25%, #5d4a1f 62.5%, #5d4a1f 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.about-page .paragraphs-wraper .cards-container .card p {
  font-size: 20px;
  color: #fff;
  width: 95%;
  margin: auto;
}
@media screen and (max-width: 1023px) {
  .about-page .paragraphs-wraper .paragraph {
    flex-direction: column;
    justify-content: center;
  }
  .about-page .paragraphs-wraper .paragraph .paragraph-img {
    height: auto;
  }
  .about-page .paragraphs-wraper .paragraph .paragraph-img,
  .about-page .paragraphs-wraper .paragraph .paragraph-content {
    width: 100%;
    padding: 15px 5px;
  }
  .about-page .paragraphs-wraper .paragraph .paragraph-img .small-card-wraper,
  .about-page .paragraphs-wraper .paragraph .paragraph-content .small-card-wraper {
    flex-direction: column;
    align-items: center;
  }
  .about-page .paragraphs-wraper .paragraph .paragraph-img .small-card-wraper .small-card,
  .about-page .paragraphs-wraper .paragraph .paragraph-content .small-card-wraper .small-card {
    width: 90%;
    min-height: 200px;
    margin-bottom: 10px;
  }
  .about-page .paragraphs-wraper .paragraph .paragraph-img .small-card-wraper .small-card h4,
  .about-page .paragraphs-wraper .paragraph .paragraph-content .small-card-wraper .small-card h4 {
    font-size: 25px;
  }
  .about-page .paragraphs-wraper .paragraph.right .paragraph-img {
    order: 1;
  }
  .about-page .paragraphs-wraper .paragraph.right .paragraph-content {
    order: 2;
  }
  .about-page .paragraphs-wraper .paragraph.left .paragraph-img {
    order: 2;
  }
  .about-page .paragraphs-wraper .paragraph.left .paragraph-content {
    order: 1;
  }
  .about-page .paragraphs-wraper .cards-container {
    flex-direction: column;
    align-items: center;
  }
  .about-page .paragraphs-wraper .cards-container .card {
    width: 80%;
    margin-bottom: 20px;
  }
}
.about-page .paragraphs-wraper .testimonials {
  background: transparent;
  padding: 0 50px 50px 50px;
  border: none;
}
.about-page .paragraphs-wraper .testimonials .title,
.about-page .paragraphs-wraper .testimonials p {
  display: none;
}
.about-page .paragraphs-wraper .testimonials .parteners {
  margin-top: 0;
}
.about-page .paragraphs-wraper.ar {
  font-family: "Noto Kufi Arabic" !important;
}
.about-page .paragraphs-wraper.ar .paragraph-content {
  text-align: right;
}
.about-page .paragraphs-wraper.ar .paragraph-content h3 {
  font-size: 35px;
  line-height: 1.9;
}
.about-page .paragraphs-wraper.ar .paragraph-content h5 {
  direction: rtl;
  line-height: 1.9;
}
.about-page .paragraphs-wraper.ar .paragraph-content p {
  font-size: 18px;
  direction: rtl;
  line-height: 1.9;
}
.about-page .paragraphs-wraper.ar .paragraph-content p span {
  margin-left: 5px;
  margin-right: 0;
}
@media screen and (max-width: 1023px) {
  .about-page .paragraphs-wraper .div-icons-wrapers {
    flex-direction: column;
    align-items: center;
  }
  .about-page .paragraphs-wraper .div-icons-wrapers .div-icon {
    width: 100% !important;
  }
  .about-page .paragraphs-wraper .div-icons-wrapers .div-icon .icon-img {
    width: 100%;
  }
  .about-page .paragraphs-wraper .div-icons-wrapers .div-icon p {
    font-size: 14px !important;
  }
}

.contacts-page {
  width: 100%;
  background-image: url("../images/imgs/bg-body4.jpg");
}
.contacts-page .container .contacts-page-wraper {
  width: 100%;
  position: relative;
  top: 100px;
  padding-bottom: 180px;
}
.contacts-page .container .contacts-page-wraper .title {
  width: 100%;
}
.contacts-page .container .contacts-page-wraper .title h2 {
  font-size: 48px;
  font-weight: 600;
  color: #FFF;
  text-align: center;
}
.contacts-page .container .contacts-page-wraper .contacts-container {
  display: flex;
  justify-content: space-between !important;
  margin-top: 30px;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-info {
  width: 49%;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-info ul li {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-info ul li img {
  width: 25px;
  height: 25px;
  display: block;
  margin-right: 15px;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-info ul li span {
  font-size: 14px;
  color: #FFF;
  display: block;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-info .map {
  width: 520px;
  height: 360px;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-info .map .map-frame {
  width: 100%;
  height: 100%;
  border: none;
  filter: saturate(30%);
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form {
  width: 48%;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form {
  width: 100%;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form input, .contacts-page .container .contacts-page-wraper .contacts-container .contact-form form textarea {
  display: block;
  width: 100%;
  font-size: 16px;
  color: #9b9b9b;
  font-weight: 300;
  padding: 15px 0;
  margin-bottom: 30px;
  border: none;
  outline: none;
  background-color: transparent;
  border-bottom: 2px solid #606060;
  resize: none;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form input:focus, .contacts-page .container .contacts-page-wraper .contacts-container .contact-form form textarea:focus {
  border-color: #8f6B29;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .msg-box label {
  display: block;
  font-size: 16px;
  color: #9b9b9b;
  margin-bottom: 15px;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .msg-box textarea {
  padding: 15px 20px;
  border: 2px solid #606060;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .msg-box textarea:focus {
  border-bottom-color: #8f6B29;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .send-btn {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: 40px;
  position: relative;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .send-btn button {
  display: block;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border: none;
  background-color: transparent;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  cursor: pointer;
  padding: 0 30px;
  position: relative;
  transition: all ease-in-out 0.5s;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .send-btn button::before {
  content: "";
  width: 1px;
  height: 15px;
  position: absolute;
  top: -15px;
  left: 0;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .send-btn button::after {
  content: "";
  width: 1px;
  height: 20px;
  position: absolute;
  bottom: -15px;
  right: 0;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .send-btn::before {
  content: "";
  width: 80px;
  height: 1px;
  position: absolute;
  right: 0;
  top: 5px;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .send-btn::after {
  content: "";
  width: 40px;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 5px;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .send-btn:hover button::before,
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .send-btn:hover button::after {
  height: 62px;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .send-btn:hover button::before {
  top: -24px;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .send-btn:hover button::after {
  bottom: -24px;
}
.contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .send-btn:hover::before, .contacts-page .container .contacts-page-wraper .contacts-container .contact-form form .send-btn:hover::after {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .contacts-page .container .contacts-page-wraper .title h2 {
    font-size: 36px;
  }
  .contacts-page .container .contacts-page-wraper .contacts-container {
    flex-direction: column;
    margin-top: 60px;
  }
  .contacts-page .container .contacts-page-wraper .contacts-container .contact-info {
    width: 95%;
    margin-bottom: 30px;
  }
  .contacts-page .container .contacts-page-wraper .contacts-container .contact-info .map {
    width: 100%;
  }
  .contacts-page .container .contacts-page-wraper .contacts-container .contact-form {
    width: 95%;
  }
}
.shop-intro {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);
  overflow: hidden;
}
.shop-intro .rec {
  position: absolute;
  top: -184px;
  right: 120px;
  width: 368px;
  height: 368px;
  border-radius: 50%;
  opacity: 0.3;
  background: rgba(0, 0, 0, 0.93);
}
.shop-intro .rec.rec-bottom {
  top: unset;
  bottom: -120px;
  left: 0;
  background: rgba(0, 0, 0, 0.93);
}
.shop-intro .container {
  width: 100%;
}
.shop-intro .container .shop-intro-container {
  padding-top: 130px;
}
.shop-intro .container .shop-intro-container .product-images-wraper {
  width: 55%;
  margin: auto;
  margin-top: 288px;
  position: relative;
}
.shop-intro .container .shop-intro-container .product-images-wraper .main-img img {
  display: block;
  width: 310px;
  rotate: -35deg;
  position: absolute;
  left: 45%;
  transform: translateX(-50%);
  top: -260px;
}
.shop-intro .container .shop-intro-container .product-images-wraper .main-img::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translateX(-50%);
  width: 316px;
  height: 15px;
  background: rgba(0, 0, 0, 0.93);
  filter: blur(9px);
  border-radius: 50%;
}
.shop-intro .container .shop-intro-container .product-images-wraper .sub-imgs {
  display: flex;
  justify-content: space-between;
}
.shop-intro .container .shop-intro-container .product-images-wraper .sub-imgs img {
  display: block;
  width: 190px;
}
.shop-intro .container .shop-intro-container .left-shap {
  position: absolute;
  display: block;
  left: -230px;
  bottom: 400px;
  opacity: 0.5;
}
.shop-intro .container .shop-intro-container .right-shap {
  position: absolute;
  display: block;
  right: -230px;
  bottom: 450px;
  opacity: 0.5;
  transform: rotate(90deg);
}
.shop-intro .container .shop-intro-container .heading {
  z-index: 3;
  padding: 40px 0 180px 0;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
}
.shop-intro .container .shop-intro-container .heading h3 {
  font-size: 75px;
  font-weight: 500;
  color: #FFF;
  margin: 47px 0 36px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  line-height: 1.2;
}
.shop-intro .container .shop-intro-container .heading .featured-ingredients {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  background-color: #222;
  border: 1px solid #FFF;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  transition: all 0.3s linear;
  overflow: hidden;
  line-height: 1;
  padding: 23px 54px 23px 54px;
}
.shop-intro .container .shop-intro-container .heading .featured-ingredients:hover {
  background-color: #FFF;
  color: #8f6B29;
  border: 1px solid #222;
}
.shop-intro .right-tree {
  position: absolute;
  top: 20%;
  right: -20px;
  z-index: 2;
}
.shop-intro .left-tree {
  position: absolute;
  top: 20%;
  left: -35px;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: translate3d(0px, -18.5545px, 0px);
  filter: blur(10px);
}
.shop-intro .right-tree-bottom {
  position: absolute;
  bottom: 10%;
  right: -20px;
  z-index: 2;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  transform: translate3d(0px, -18.5545px, 0px);
  filter: blur(10px);
}
.shop-intro .left-tree-bottom {
  position: absolute;
  bottom: 10%;
  left: -60px;
  z-index: 1;
}
.shop-intro::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 50px;
  background-image: url(../images/imgs/banner-bg-shape.png);
  background-repeat: repeat-x;
  background-position: center;
}

@media screen and (max-width: 480px) {
  .shop-intro .container .shop-intro-container .product-images-wraper .main-img img {
    rotate: 0deg;
    left: 50%;
    top: -200px;
  }
  .shop-intro .container .shop-intro-container .product-images-wraper .sub-imgs {
    display: none;
  }
  .shop-intro .container .shop-intro-container .left-shap, .shop-intro .container .shop-intro-container .right-shap {
    display: none;
  }
  .shop-intro .container .shop-intro-container .heading {
    padding-top: 240px;
  }
  .shop-intro .container .shop-intro-container .heading h3 {
    font-size: 36px;
    font-weight: 600;
    text-align: center;
  }
  .shop-intro .container .shop-intro-container .heading .featured-ingredients {
    width: 70%;
  }
  .shop-intro .rec.rec-bottom {
    bottom: -222px;
    left: 10px;
  }
}
.shop-navbar {
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;
  z-index: 9;
}
.shop-navbar.onscroll {
  position: fixed;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  animation: 0.95s ease 0s normal forwards 1 running headerSlideDown;
  box-shadow: 0px 4px 10px rgba(3, 4, 28, 0.1);
  transition: all ease-in-out 0.3s;
}
.shop-navbar .container {
  width: 95%;
}
.shop-navbar .container .shop-navbar-container {
  display: flex;
  align-items: center;
}
.shop-navbar .container .shop-navbar-container .mobile-menu {
  width: 60px;
  height: 60px;
  margin-left: auto;
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
}
.shop-navbar .container .shop-navbar-container .mobile-menu img {
  width: 35px;
  height: 35px;
}
.shop-navbar .container .shop-navbar-container .nav-links {
  display: flex;
  width: 45%;
  transition: all ease-in-out 0.3s;
}
.shop-navbar .container .shop-navbar-container .nav-links .close-menu {
  display: none;
}
.shop-navbar .container .shop-navbar-container .nav-links li {
  display: block;
  margin-right: 50px;
}
.shop-navbar .container .shop-navbar-container .nav-links li a {
  font-size: 16px;
  font-weight: 500;
  color: #FFF;
  padding: 28px 0;
  display: flex;
  align-items: center;
  line-height: 1;
}
.shop-navbar .container .shop-navbar-container .nav-links li a img {
  width: 25px;
  height: 25px;
  display: block;
  margin-right: 10px;
  display: none;
}
.shop-navbar .container .shop-navbar-container .nav-links li a.active {
  color: #8f6B29;
}
.shop-navbar .container .shop-navbar-container .nav-links .login, .shop-navbar .container .shop-navbar-container .nav-links .signup {
  display: none;
}
.shop-navbar .container .shop-navbar-container .nav-links .social-icons {
  width: 100%;
  display: none;
}
.shop-navbar .container .shop-navbar-container .nav-links .social-icons .icons-container {
  width: 100%;
}
.shop-navbar .container .shop-navbar-container .nav-links .social-icons .icons-container a {
  display: block;
}
.shop-navbar .container .shop-navbar-container .nav-links .social-icons .icons-container a img {
  width: 25px;
  height: 25px;
}
.shop-navbar .container .shop-navbar-container .shop-logo {
  width: 20%;
  text-align: center;
}
.shop-navbar .container .shop-navbar-container .shop-logo img {
  width: 50%;
}
.shop-navbar .container .shop-navbar-container .icons-container {
  width: 35%;
  display: flex;
  justify-content: flex-end;
}
.shop-navbar .container .shop-navbar-container .icons-container .search {
  width: 50px;
  height: 50px;
  padding: 5px 20px 5px 5px;
  position: relative;
  cursor: pointer;
}
.shop-navbar .container .shop-navbar-container .icons-container .search img {
  width: 100%;
  height: 100%;
}
.shop-navbar .container .shop-navbar-container .icons-container .search::after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 50%;
  background-color: #FFF;
}
.shop-navbar .container .shop-navbar-container .icons-container .login-register {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 20px 0 0;
  position: relative;
  margin-left: 20px;
  position: relative;
}
.shop-navbar .container .shop-navbar-container .icons-container .login-register .user-menu {
  position: absolute;
  width: 100%;
  top: 60px;
  left: 0px;
  z-index: -1;
  padding: 15px;
  display: none;
  background-color: #333;
}
.shop-navbar .container .shop-navbar-container .icons-container .login-register .user-menu a {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 15px;
  padding: 5px;
  font-weight: 500;
  color: #FFF;
}
.shop-navbar .container .shop-navbar-container .icons-container .login-register .user-menu a:last-of-type {
  margin-bottom: 0;
}
.shop-navbar .container .shop-navbar-container .icons-container .login-register .user-menu a img {
  margin-right: 5px;
}
.shop-navbar .container .shop-navbar-container .icons-container .login-register .user-menu.show {
  display: block !important;
}
.shop-navbar .container .shop-navbar-container .icons-container .login-register img {
  width: 25px;
  height: 25px;
}
.shop-navbar .container .shop-navbar-container .icons-container .login-register span {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding-left: 12px;
  color: #FFF;
}
.shop-navbar .container .shop-navbar-container .icons-container .login-register::after {
  content: "";
  width: 8px;
  height: 8px;
  border: 2px solid #FFF;
  border-top-color: transparent;
  border-left-color: transparent;
  position: absolute;
  right: 0;
  top: 33%;
  transform: rotate(45deg);
}
.shop-navbar .container .shop-navbar-container .icons-container .cart a {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  cursor: pointer;
  position: relative;
}
.shop-navbar .container .shop-navbar-container .icons-container .cart a span {
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 12px;
  font-weight: 500;
  width: 15px;
  height: 15px;
  background-color: #8f6B29;
  color: #FFF;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shop-navbar .container .shop-navbar-container .icons-container .cart img {
  width: 30px;
  height: 30px;
}

@media screen and (max-width: 480px) {
  .shop-navbar {
    padding: 10px 0;
  }
  .shop-navbar .container .shop-navbar-container .nav-links {
    width: 65%;
    margin-right: -65%;
    height: 100vh;
    background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);
    position: fixed;
    right: 0;
    top: 0;
    padding: 80px 10px;
    flex-direction: column;
  }
  .shop-navbar .container .shop-navbar-container .nav-links li a img {
    display: block;
  }
  .shop-navbar .container .shop-navbar-container .nav-links .login, .shop-navbar .container .shop-navbar-container .nav-links .signup {
    display: flex;
  }
  .shop-navbar .container .shop-navbar-container .nav-links .social-icons {
    display: flex;
  }
  .shop-navbar .container .shop-navbar-container .nav-links .social-icons .icons-container {
    display: flex;
    justify-content: space-around;
  }
  .shop-navbar .container .shop-navbar-container .nav-links .close-menu {
    display: block;
    background-color: #8f6B29;
    width: 30px;
    height: 30px;
    padding: 5px;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    top: 40px;
    transform: translateX(-50%);
    cursor: pointer;
  }
  .shop-navbar .container .shop-navbar-container .nav-links.show-mobile-menu {
    margin-right: 0;
  }
  .shop-navbar .container .shop-navbar-container .shop-logo {
    width: 35%;
  }
  .shop-navbar .container .shop-navbar-container .shop-logo img {
    width: 70%;
  }
  .shop-navbar .container .shop-navbar-container .icons-container {
    display: none;
  }
  .shop-navbar .container .shop-navbar-container .mobile-menu {
    display: flex;
  }
}
.product-info {
  margin-top: 50px;
  padding-top: 50px;
  position: relative;
  overflow: hidden;
}
.product-info .left {
  position: absolute;
  left: -40px;
  top: 100px;
  filter: blur(5px);
}
.product-info .right {
  position: absolute;
  right: -60px;
  top: 100px;
  transform: rotate(220deg);
}
.product-info .section-title h5 {
  color: #333;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.16px;
  text-transform: uppercase;
  margin-bottom: 150px;
  text-align: center;
  line-height: 1.2;
}
.product-info .product-info-container {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.product-info .product-info-container .product-img {
  width: 45%;
}
.product-info .product-info-container .product-img img {
  width: 100%;
}
.product-info .product-info-container .product-details {
  width: 40%;
}
.product-info .product-info-container .product-details h4 {
  margin-bottom: 34px;
  font-size: 26px;
  line-height: 1.35;
  letter-spacing: -0.01em;
  font-weight: 500;
  text-transform: uppercase;
  color: #1B1819;
}
.product-info .product-info-container .product-details p {
  width: 85%;
  line-height: 28px;
  margin-bottom: 35px;
  font-size: 16px;
  font-weight: 400;
  color: #82848B;
}
.product-info .product-info-container .product-details ul {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px;
}
.product-info .product-info-container .product-details ul li {
  display: flex;
  align-items: center;
  width: 50%;
  flex-shrink: 0;
  font-size: 16px;
  color: #82848B;
  line-height: 29px;
  margin: 0 0 9px;
}
.product-info .product-info-container .product-details ul li img {
  display: block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.product-info .product-info-container .product-details .line-after {
  margin-bottom: 42px;
}
.product-info .product-info-container .product-details .line-after p {
  font-size: 16px;
  font-weight: 500;
  color: #1B1819;
  line-height: 28px;
}
.product-info .product-info-container .product-details .btns-wraper .link-btn {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #1B1819;
  border: 1px solid #1B1819;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  position: relative;
  transition: all 0.3s linear;
  overflow: hidden;
  line-height: 1;
  padding: 22px 90px 22px 40px;
  z-index: 1;
  margin-bottom: 20px;
}
.product-info .product-info-container .product-details .btns-wraper .link-btn::after {
  position: absolute;
  content: "";
  border: 2px solid #FFF;
  border-top-color: transparent;
  border-left-color: transparent;
  width: 7px;
  height: 7px;
  right: 40px;
  top: 50%;
  border-radius: 0px;
  transform: translate(-50%, -50%) rotate(-45deg);
  z-index: -1;
  transition: all 500ms ease;
}
.product-info .product-info-container .product-details .btns-wraper .link-btn:hover {
  background-color: transparent;
  color: #1B1819;
}
.product-info .product-info-container .product-details .btns-wraper .link-btn:hover::after {
  border-bottom-color: #1B1819;
  border-right-color: #1B1819;
}
.product-info .product-info-container .product-details .btns-wraper .link-btn:last-of-type {
  background-color: transparent;
  color: #1B1819;
}
.product-info .product-info-container .product-details .btns-wraper .link-btn:last-of-type::after {
  border-bottom-color: #1B1819;
  border-right-color: #1B1819;
}
.product-info .product-info-container .product-details .btns-wraper .link-btn:last-of-type:hover {
  background-color: #1B1819;
  color: #FFF;
}
.product-info .product-info-container .product-details .btns-wraper .link-btn:last-of-type:hover::after {
  border-bottom-color: #FFF;
  border-right-color: #FFF;
}

@media screen and (max-width: 480px) {
  .product-info .product-info-container {
    flex-direction: column;
  }
  .product-info .product-info-container .product-img {
    width: 100%;
    margin-bottom: 30px;
  }
  .product-info .product-info-container .product-details {
    width: 100%;
  }
  .product-info .product-info-container .product-details p {
    width: 100%;
  }
  .product-info .product-info-container .product-details ul li img {
    margin-right: 5px;
  }
  .product-info .product-info-container .product-details .btns-wraper .link-btn {
    width: 220px;
  }
}
.best-products {
  margin-top: 80px;
}
.best-products .section-title h2 {
  font-weight: 600;
  font-size: 40px;
  letter-spacing: -0.45px;
  text-transform: uppercase;
  line-height: 1.2;
  text-align: center;
  color: #1B1819;
}
.best-products .best-products-wraper {
  margin-top: 50px;
}
.best-products .best-products-wraper .swiper .swiper-slide {
  flex-shrink: 0;
  border-radius: 20px;
}
.best-products .best-products-wraper .swiper .swiper-slide:last-of-type {
  margin-right: 80px;
}
.best-products .best-products-wraper .swiper .swiper-button-prev, .best-products .best-products-wraper .swiper .swiper-button-next {
  left: 30px;
  background-color: #FFF;
  width: 30px;
  height: 30px;
  padding-left: 5px;
  border-radius: 50%;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}
.best-products .best-products-wraper .swiper .swiper-button-prev::after, .best-products .best-products-wraper .swiper .swiper-button-next::after {
  content: "";
  width: 8px;
  height: 8px;
  border: 3px solid #8f6B29;
  border-top-color: transparent;
  border-right-color: transparent;
  transform: rotate(45deg);
}
.best-products .best-products-wraper .swiper .swiper-button-next {
  left: unset !important;
  right: 30px !important;
  padding-left: 0 !important;
  padding-right: 5px;
}
.best-products .best-products-wraper .swiper .swiper-button-next::after {
  border: 3px solid #8f6B29;
  border-bottom-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

@media screen and (max-width: 480px) {
  .best-products .section-title h2 {
    font-size: 30px;
  }
}
.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  border: 1px solid #eaeaea;
  border-radius: 20px;
}
.product .product-img {
  display: block;
  width: 80%;
  height: 50%;
}
.product .product-img a {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}
.product .product-img a img {
  width: 80%;
  -o-object-fit: cover;
     object-fit: cover;
}
.product h3 a {
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 20px;
  margin-top: 40px;
  text-align: center;
  color: #1B1819;
  transition: all ease-in-out 0.3s;
}
.product h3 a:hover {
  color: #8f6B29;
}
.product .price {
  display: flex;
  justify-content: center;
}
.product .price p {
  font-size: 20px;
  display: block;
  font-weight: 600;
  color: #8f6B29;
  line-height: 1;
  margin-bottom: 28px;
  margin-right: 15px;
}
.product .price p.price-before {
  color: #82848B;
  text-decoration: line-through;
  opacity: 0.8;
}
.product .rating {
  display: flex;
  justify-content: center;
}
.product .rating img {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.product .rating img:last-of-type {
  margin-right: 0;
}
.product .add-to-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 28px;
  cursor: pointer;
}
.product .add-to-cart img {
  width: 45px;
  height: 45px;
  padding: 8px;
  border-radius: 50%;
  background-color: #1B1819;
}
.product .add-to-cart span {
  padding: 17px 34px;
  border: 1px solid #EAEAEA;
  background: #F9F9F9;
  color: #1B1819;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}
.product .add-to-cart span:hover {
  background-color: #8f6B29;
  color: #FFF;
  transition: all ease-in-out 0.3s;
}
.product .add-to-cart button, .product .add-to-cart .edit {
  display: block;
  width: 45%;
  padding: 4px 0 3px;
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin-right: 10px;
  color: #FFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: rgb(5, 139, 57);
}
.product .add-to-cart button.delete, .product .add-to-cart .edit.delete {
  margin-right: 0;
  background-color: rgb(185, 11, 11);
  padding: 7px 0 7px;
}
.product:hover {
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}

.product-features .product-features-container {
  margin-top: 75px;
  padding: 148px 0 100px 0;
  position: relative;
  margin-bottom: 100px;
  z-index: 1;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);
}
.product-features .product-features-container .section-title {
  margin-bottom: 150px;
}
.product-features .product-features-container .section-title h3 {
  font-weight: 600;
  font-size: 45px;
  letter-spacing: -0.45px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
}
.product-features .product-features-container::before {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  width: 100%;
  height: 61px;
  background-image: url(../images/imgs/feature-bg-shape-1.png);
  background-repeat: repeat-x;
  background-position: center;
  z-index: -1;
}
.product-features .product-features-container::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 61px;
  background-image: url(../images/imgs/feature-bg-shape-2.png);
  background-repeat: repeat-x;
  background-position: center;
  z-index: -1;
}
.product-features .product-features-container .product-features-wraper {
  width: 90%;
  margin: auto;
  display: flex;
}
.product-features .product-features-container .product-features-wraper .left,
.product-features .product-features-container .product-features-wraper .right {
  width: 25%;
  list-style: none;
}
.product-features .product-features-container .product-features-wraper .left li,
.product-features .product-features-container .product-features-wraper .right li {
  margin-bottom: 65px;
}
.product-features .product-features-container .product-features-wraper .left li .shap-icon,
.product-features .product-features-container .product-features-wraper .right li .shap-icon {
  display: flex;
  align-items: center;
}
.product-features .product-features-container .product-features-wraper .left li .shap-icon span,
.product-features .product-features-container .product-features-wraper .right li .shap-icon span {
  background: #fff;
  margin-right: 22px;
  -webkit-clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
  clip-path: polygon(45% 1.33975%, 46.5798% 0.60307%, 48.26352% 0.15192%, 50% 0%, 51.73648% 0.15192%, 53.4202% 0.60307%, 55% 1.33975%, 89.64102% 21.33975%, 91.06889% 22.33956%, 92.30146% 23.57212%, 93.30127% 25%, 94.03794% 26.5798%, 94.48909% 28.26352%, 94.64102% 30%, 94.64102% 70%, 94.48909% 71.73648%, 94.03794% 73.4202%, 93.30127% 75%, 92.30146% 76.42788%, 91.06889% 77.66044%, 89.64102% 78.66025%, 55% 98.66025%, 53.4202% 99.39693%, 51.73648% 99.84808%, 50% 100%, 48.26352% 99.84808%, 46.5798% 99.39693%, 45% 98.66025%, 10.35898% 78.66025%, 8.93111% 77.66044%, 7.69854% 76.42788%, 6.69873% 75%, 5.96206% 73.4202%, 5.51091% 71.73648%, 5.35898% 70%, 5.35898% 30%, 5.51091% 28.26352%, 5.96206% 26.5798%, 6.69873% 25%, 7.69854% 23.57212%, 8.93111% 22.33956%, 10.35898% 21.33975%);
  height: 75px;
  width: 75px;
  text-align: center;
  line-height: 80px;
  flex: 0 0 auto;
  display: inline-block;
  transition: 0.6s;
  padding-top: 7px;
}
.product-features .product-features-container .product-features-wraper .left li .shap-icon span img,
.product-features .product-features-container .product-features-wraper .right li .shap-icon span img {
  width: 35px;
  height: 35px;
}
.product-features .product-features-container .product-features-wraper .left li .shap-icon h6,
.product-features .product-features-container .product-features-wraper .right li .shap-icon h6 {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.01em;
  line-height: 1.2;
  text-transform: uppercase;
}
.product-features .product-features-container .product-features-wraper .product-img {
  width: 50%;
  text-align: center;
  position: relative;
}
.product-features .product-features-container .product-features-wraper .product-img .img-product {
  display: block;
  width: 350px;
  margin: auto;
  position: absolute;
  top: -10px;
  left: 25%;
  transform: rotate(-40deg);
}
.product-features .product-features-container .product-features-wraper .product-img .top-shap {
  position: absolute;
  top: -30px;
  right: 120px;
}
.product-features .product-features-container .product-features-wraper .product-img .bottom-shap {
  position: absolute;
  bottom: -100px;
  left: 150px;
  filter: blur(5px);
}
.product-features .product-features-container .product-features-wraper .product-img::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -130px;
  transform: translateX(-50%);
  width: 316px;
  height: 15px;
  background: rgba(131, 131, 131, 0.54);
  filter: blur(9px);
  border-radius: 50%;
  z-index: -1;
}

@media screen and (max-width: 480px) {
  .product-features .product-features-container {
    margin-bottom: 50px;
  }
  .product-features .product-features-container .section-title h3 {
    font-size: 34px;
  }
  .product-features .product-features-container .product-features-wraper {
    flex-direction: column;
  }
  .product-features .product-features-container .product-features-wraper .product-img {
    margin-bottom: 50px;
  }
  .product-features .product-features-container .product-features-wraper .product-img .img-product {
    position: unset;
    transform: rotate(0deg);
  }
  .product-features .product-features-container .product-features-wraper .right, .product-features .product-features-container .product-features-wraper .left {
    width: 100%;
  }
  .product-features .product-features-container .product-features-wraper .right li .shap-icon h6, .product-features .product-features-container .product-features-wraper .left li .shap-icon h6 {
    font-size: 20px;
  }
}
.ask {
  margin-top: 150px;
}
.ask .title-section {
  margin-bottom: 65px;
}
.ask .title-section h3 {
  font-weight: 600;
  font-size: 40px;
  letter-spacing: -0.45px;
  text-transform: uppercase;
  line-height: 1.2;
  color: #1B1819;
  text-align: center;
}

.ask-container {
  width: 80%;
  margin: auto;
  padding: 50px;
  border-radius: 30px;
  background-color: #f9f9f9;
}
.ask-container .ask-box {
  padding: 30px 0;
  border-bottom: 1px solid #EAEAEA;
}
.ask-container .ask-box h4 {
  font-size: 18px;
  font-weight: 600;
  color: #1B1819;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ask-container .ask-box h4 span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 600;
  color: #5F6168;
  background-color: #FFF;
  margin-left: auto;
}
.ask-container .ask-box .answer {
  padding: 20px;
  background-color: #FFF;
  border-radius: 10px;
  margin-top: 20px;
}
.ask-container .ask-box .answer p {
  color: #5F6168;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
}

@media screen and (max-width: 480px) {
  .ask {
    margin-top: 100px;
  }
  .ask .title-section h3 {
    font-size: 34px;
  }
  .ask .ask-container {
    width: 95%;
    padding: 50px 20px;
  }
  .ask .ask-container .ask-box h4 {
    font-size: 20px;
  }
}
.single-product-page .single-product-intro {
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.single-product-page .single-product-intro .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
}
.single-product-page .single-product-intro .rec {
  position: absolute;
  top: -184px;
  right: 120px;
  width: 368px;
  height: 368px;
  border-radius: 50%;
  opacity: 0.3;
  background: rgba(0, 0, 0, 0.93);
}
.single-product-page .single-product-intro .rec.rec-bottom {
  top: unset;
  bottom: -120px;
  left: 0;
  background: rgba(0, 0, 0, 0.93);
}
.single-product-page .single-product-intro .container {
  width: 100%;
}
.single-product-page .single-product-intro .container .product-intro-container {
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.single-product-page .single-product-intro .container .product-intro-container .page-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.single-product-page .single-product-intro .container .product-intro-container .page-navigation a,
.single-product-page .single-product-intro .container .product-intro-container .page-navigation span {
  display: block;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.single-product-page .single-product-intro .container .product-intro-container .page-navigation a.product-details,
.single-product-page .single-product-intro .container .product-intro-container .page-navigation span.product-details {
  color: #8f6B29;
}
.single-product-page .single-product-intro .container .product-intro-container .product-title {
  width: 80%;
  margin: auto;
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  text-align: center;
}
.single-product-page .single-product-intro::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 50px;
  background-image: url(../images/imgs/banner-bg-shape.png);
  background-repeat: repeat-x;
  background-position: center;
}
.single-product-page .product-details-container {
  width: 80%;
  margin: auto;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}
.single-product-page .product-details-container .product-imgs {
  width: 47%;
  height: 440px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.single-product-page .product-details-container .product-imgs img {
  width: 45%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px;
}
.single-product-page .product-details-container .product-details {
  width: 50%;
}
.single-product-page .product-details-container .product-details .product-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.1;
  color: #1B1819;
}
.single-product-page .product-details-container .product-details .stock-rating {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.single-product-page .product-details-container .product-details .stock-rating .stock {
  display: block;
  padding: 5px 10px;
  margin-right: 15px;
  background: #f9f9f9;
  color: #8f6B29;
  font-size: 14px;
  font-weight: 500;
}
.single-product-page .product-details-container .product-details .stock-rating .rating {
  display: flex;
  align-items: center;
}
.single-product-page .product-details-container .product-details .stock-rating .rating span {
  font-size: 25px !important;
}
.single-product-page .product-details-container .product-details .product-des {
  font-size: 14px;
  font-weight: 400;
  color: #82848B;
  margin-bottom: 15px;
  margin-top: 15px;
  line-height: 26px;
}
.single-product-page .product-details-container .product-details .product-price {
  display: flex;
  align-items: center;
}
.single-product-page .product-details-container .product-details .product-price .price-before {
  font-size: 20px;
  color: #82848b;
  margin-right: 10px;
  font-weight: 600;
  text-decoration: line-through;
  line-height: 1;
}
.single-product-page .product-details-container .product-details .product-price .price-after {
  font-size: 28px;
  font-weight: 600;
  line-height: 1;
  color: #1B1819;
}
.single-product-page .product-details-container .product-details .qty {
  display: flex;
  align-items: center;
  margin-top: 35px;
}
.single-product-page .product-details-container .product-details .qty .add-to-wishlist {
  padding-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1B1819;
  width: 55px;
  height: 54px;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  cursor: pointer;
}
.single-product-page .product-details-container .product-details .qty .add-to-wishlist img {
  width: 40px;
  height: 40px;
}
.single-product-page .product-details-container .product-details .qty .add-to-cart {
  margin-left: 5px;
  display: inline-block;
  width: 70%;
  background-color: #1B1819;
  border: 1px solid #1B1819;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  text-align: center;
  line-height: 1;
  padding: 19px 41px;
  cursor: pointer;
}
.single-product-page .product-details-container .product-details .qty .add-to-cart:hover {
  background-color: #fff;
  border-color: #8f6B29;
  color: #8f6B29;
  transition: all ease-in-out 0.3s;
}
.single-product-page .description-reviews {
  width: 80%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 100px;
}
.single-product-page .description-reviews ul li {
  display: inline-block;
  margin-right: 24px;
  background: #f9f9f9;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
  color: #1B1819;
  padding: 20px 50px;
  cursor: pointer;
}
.single-product-page .description-reviews ul li.active {
  background-color: #8f6B29;
  color: #fff;
}
.single-product-page .description-reviews .boxs-container {
  margin-top: 40px;
}
.single-product-page .description-reviews .boxs-container .desc-box p, .single-product-page .description-reviews .boxs-container .reviews-box p {
  font-size: 16px;
  font-weight: 400;
  color: #82848B;
  margin-bottom: 15px;
  line-height: 26px;
}
.single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews p, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews p {
  color: #1B1819;
  font-weight: 600;
}
.single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .write-review, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .write-review {
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 500;
  border: 1px solid #1B1819;
  cursor: pointer;
}
.single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .success-msg, .single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .error-msg, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .success-msg, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .error-msg {
  display: flex;
  align-items: center;
  width: 50%;
  padding: 10px;
  margin-top: 20px;
  background-color: rgb(90, 206, 138);
  font-size: 16px;
  font-weight: 500;
  color: #FFF;
}
.single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .success-msg small, .single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .error-msg small, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .success-msg small, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .error-msg small {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: rgb(7, 126, 57);
}
.single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .success-msg .review-text, .single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .error-msg .review-text, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .success-msg .review-text, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .error-msg .review-text {
  font-size: 16px;
  font-weight: 500;
  color: #FFF;
  margin-bottom: 0;
  margin-left: 10px;
}
.single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .success-msg.error-msg, .single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .error-msg.error-msg, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .success-msg.error-msg, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .error-msg.error-msg {
  background-color: rgb(238, 88, 88);
}
.single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .success-msg.error-msg small, .single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .error-msg.error-msg small, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .success-msg.error-msg small, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .error-msg.error-msg small {
  color: rgb(126, 7, 13);
}
.single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .review-box, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .review-box {
  margin-top: 30px;
}
.single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .review-box .review-rating, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .review-box .review-rating {
  display: flex;
  align-items: center;
}
.single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .review-box .review-rating h5, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .review-box .review-rating h5 {
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
  color: #1B1819;
}
.single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .review-box textarea, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .review-box textarea {
  display: block;
  width: 50%;
  height: 100px;
  font-size: 16px;
  font-weight: 500;
  margin: 10px 0;
  color: #1B1819;
  padding: 10px;
  resize: none;
  border: 1px solid #82848B;
  outline: none;
}
.single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .review-box button, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .review-box button {
  padding: 10px;
  border: none;
  background-color: #1B1819;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.single-product-page .description-reviews .boxs-container .desc-box .reviews-wraper, .single-product-page .description-reviews .boxs-container .reviews-box .reviews-wraper {
  margin-top: 50px;
}
.single-product-page .description-reviews .boxs-container .desc-box .reviews-wraper .review .user, .single-product-page .description-reviews .boxs-container .reviews-box .reviews-wraper .review .user {
  display: flex;
  align-items: center;
}
.single-product-page .description-reviews .boxs-container .desc-box .reviews-wraper .review .user img, .single-product-page .description-reviews .boxs-container .reviews-box .reviews-wraper .review .user img {
  display: block;
  width: 40px;
  height: 40px;
  padding: 10px;
  background-color: #8f6B29;
  border-radius: 50%;
}
.single-product-page .description-reviews .boxs-container .desc-box .reviews-wraper .review .user h6, .single-product-page .description-reviews .boxs-container .reviews-box .reviews-wraper .review .user h6 {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  color: #1B1819;
  margin-left: 10px;
}
.single-product-page .description-reviews .boxs-container .desc-box .reviews-wraper .review .rating, .single-product-page .description-reviews .boxs-container .reviews-box .reviews-wraper .review .rating {
  width: -moz-fit-content;
  width: fit-content;
}
.single-product-page .description-reviews .boxs-container .desc-box .reviews-wraper .review .rating span, .single-product-page .description-reviews .boxs-container .reviews-box .reviews-wraper .review .rating span {
  font-size: 20px !important;
}
.single-product-page .description-reviews .boxs-container .desc-box .reviews-wraper .review p, .single-product-page .description-reviews .boxs-container .reviews-box .reviews-wraper .review p {
  font-size: 16px;
  font-weight: 400;
  color: #82848B;
}
.single-product-page .look-alike {
  width: 80%;
  margin: auto;
}
.single-product-page .look-alike .title {
  margin-bottom: 60px;
}
.single-product-page .look-alike .title h3 {
  font-weight: 600;
  font-size: 42px;
  letter-spacing: -0.45px;
  text-transform: uppercase;
  text-align: center;
  color: #1B1819;
}
.single-product-page .look-alike .product-container .swiper .swiper-slide {
  flex-shrink: 0;
  border-radius: 20px;
}
.single-product-page .look-alike .product-container .swiper .swiper-slide:last-of-type {
  margin-right: 80px;
}
.single-product-page .look-alike .product-container .swiper .swiper-button-prev,
.single-product-page .look-alike .product-container .swiper .swiper-button-next {
  left: 30px;
  background-color: #fff;
  width: 30px;
  height: 30px;
  padding-left: 5px;
  border-radius: 50%;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}
.single-product-page .look-alike .product-container .swiper .swiper-button-prev::after,
.single-product-page .look-alike .product-container .swiper .swiper-button-next::after {
  content: "";
  width: 8px;
  height: 8px;
  border: 3px solid #8f6B29;
  border-top-color: transparent;
  border-right-color: transparent;
  transform: rotate(45deg);
}
.single-product-page .look-alike .product-container .swiper .swiper-button-next {
  left: unset !important;
  right: 30px !important;
  padding-left: 0 !important;
  padding-right: 5px;
}
.single-product-page .look-alike .product-container .swiper .swiper-button-next::after {
  border: 3px solid #8f6B29;
  border-bottom-color: transparent;
  border-left-color: transparent;
  transform: rotate(45deg);
}

@media screen and (max-width: 480px) {
  .single-product-page .single-product-intro .rec-bottom {
    bottom: -200px !important;
  }
  .single-product-page .single-product-intro .container .product-intro-container {
    width: 95%;
  }
  .single-product-page .single-product-intro .container .product-intro-container .product-title {
    width: 95%;
    font-size: 36px;
  }
  .single-product-page .product-details-container {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
  .single-product-page .product-details-container .product-imgs {
    width: 85%;
  }
  .single-product-page .product-details-container .product-details {
    width: 85%;
    margin-top: 20px;
  }
  .single-product-page .product-details-container .product-details .qty {
    flex-wrap: wrap;
  }
  .single-product-page .product-details-container .product-details .qty .add-to-cart {
    flex-shrink: 0;
  }
  .single-product-page .description-reviews {
    width: 85%;
  }
  .single-product-page .description-reviews ul {
    display: flex;
  }
  .single-product-page .description-reviews ul li {
    font-size: 16px;
    padding: 15px 30px;
  }
  .single-product-page .description-reviews .boxs-container .desc-box p, .single-product-page .description-reviews .boxs-container .reviews-box p {
    font-size: 14px;
  }
  .single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .review-box textarea, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .review-box textarea {
    width: 100%;
  }
  .single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .success-msg, .single-product-page .description-reviews .boxs-container .desc-box .add-new-reviews .error-msg, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .success-msg, .single-product-page .description-reviews .boxs-container .reviews-box .add-new-reviews .error-msg {
    width: 100%;
  }
  .single-product-page .look-alike {
    width: 85%;
  }
  .single-product-page .look-alike .title h3 {
    font-size: 32px;
  }
}
.footer-shop {
  padding: 100px 0 30px 0;
  position: relative;
  z-index: 1;
  margin-top: 100px;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);
}
.footer-shop::before {
  content: "";
  position: absolute;
  left: 0;
  top: -10px;
  width: 100%;
  height: 58px;
  background-image: url(../images/imgs/footer-shape-01.png);
  background-repeat: repeat-x;
  background-position: center;
  z-index: -1;
}
.footer-shop .footer-container .inner-footer {
  width: 80%;
  margin: auto;
  padding: 60px 0px 40px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
}
.footer-shop .footer-container .inner-footer .footer-links {
  width: 35%;
}
.footer-shop .footer-container .inner-footer .footer-links ul {
  padding-left: 50px;
  display: flex;
  list-style: none;
}
.footer-shop .footer-container .inner-footer .footer-links ul li a {
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  text-transform: capitalize;
  margin-right: 36px;
  padding: 5px;
  position: relative;
}
.footer-shop .footer-container .inner-footer .footer-links ul li a::after {
  content: "";
  width: 0;
  height: 1px;
  background-color: transparent;
  position: absolute;
  left: 0;
  bottom: 0;
}
.footer-shop .footer-container .inner-footer .footer-links ul li a:hover::after {
  width: 95%;
  background-color: #8f6B29;
  transition: all ease-in-out 0.5s;
}
.footer-shop .footer-container .inner-footer .footer-logo {
  width: 30%;
  text-align: center;
}
.footer-shop .footer-container .inner-footer .footer-logo img {
  width: 100px;
  -o-object-fit: cover;
     object-fit: cover;
}
.footer-shop .footer-container .inner-footer .footer-social {
  width: 35%;
  padding-right: 50px;
  text-align: right;
}
.footer-shop .footer-container .inner-footer .footer-social a {
  display: inline-block;
  padding: 5px;
  margin-left: 36px;
}
.footer-shop .footer-container .inner-footer .footer-social a:first-of-type {
  margin-left: 0;
}
.footer-shop .footer-container .inner-footer .footer-social a img {
  width: 25px;
  height: 25px;
}
.footer-shop .footer-container .end-footer {
  display: flex;
  width: 80%;
  margin: auto;
  padding: 50px 50px 0 50px;
}
.footer-shop .footer-container .end-footer .copyright {
  width: 50%;
  color: #fff;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.footer-shop .footer-container .end-footer .copyright a {
  color: #fff;
  margin-left: 5px;
  transition: all ease-in-out 0.3s;
}
.footer-shop .footer-container .end-footer .copyright a:hover {
  color: #8f6B29;
}
.footer-shop .footer-container .end-footer .pages {
  width: 50%;
  text-align: right;
}
.footer-shop .footer-container .end-footer .pages a {
  display: inline-block;
  margin-left: 20px;
  color: #fff;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
}
.footer-shop .footer-container .end-footer .pages a:first-of-type {
  margin-left: 0;
}
.footer-shop .footer-container .footer-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  width: 100%;
  padding: 15px 10px 5px 10px;
  background-color: #333;
  display: none;
}
.footer-shop .footer-container .footer-navigation ul {
  list-style: none;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.footer-shop .footer-container .footer-navigation ul li {
  position: relative;
}
.footer-shop .footer-container .footer-navigation ul li img {
  width: 25px;
  height: 25px;
}
.footer-shop .footer-container .footer-navigation ul li span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #8f6B29;
  color: #fff;
  position: absolute;
  top: -5px;
  left: -12px;
}

@media screen and (max-width: 480px) {
  .footer-shop .footer-container .inner-footer {
    flex-direction: column;
  }
  .footer-shop .footer-container .inner-footer .footer-links,
  .footer-shop .footer-container .inner-footer .footer-logo,
  .footer-shop .footer-container .inner-footer .footer-social {
    width: 100%;
    margin-bottom: 30px;
  }
  .footer-shop .footer-container .inner-footer .footer-links ul,
  .footer-shop .footer-container .inner-footer .footer-logo ul,
  .footer-shop .footer-container .inner-footer .footer-social ul {
    padding: 0;
    justify-content: space-between;
  }
  .footer-shop .footer-container .inner-footer .footer-links ul li a,
  .footer-shop .footer-container .inner-footer .footer-logo ul li a,
  .footer-shop .footer-container .inner-footer .footer-social ul li a {
    margin-right: 0;
  }
  .footer-shop .footer-container .end-footer {
    flex-direction: column;
    width: 90%;
    padding: 10px 10px 0 10px;
  }
  .footer-shop .footer-container .end-footer .copyright {
    width: 80%;
    margin: auto;
    flex-direction: column;
    align-items: center;
  }
  .footer-shop .footer-container .end-footer .pages {
    width: 80%;
    margin: auto;
    margin-top: 20px;
  }
  .footer-shop .footer-container .footer-navigation {
    display: flex;
  }
}
.our-blogs .section-title h2 {
  padding: 50px 0 80px 0;
  font-weight: 600;
  font-size: 45px;
  letter-spacing: -0.45px;
  color: #1B1819;
  text-align: center;
}
.our-blogs .our-blogs-container {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.our-blogs .our-blogs-container .article {
  width: 31.6666666667%;
}
.our-blogs .our-blogs-container .article .art-img {
  width: 100%;
  height: 200px;
  -webkit-mask-position: top center;
  mask-position: top center;
  -webkit-mask-image: url(../images/imgs/blog-mask-1.png);
  mask-image: url(../images/imgs/blog-mask-1.png);
  -webkit-mask-position: top left;
  mask-position: top left;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: 343.75px 212.76px;
  mask-size: 343.75px 212.76px;
  -o-object-fit: cover;
     object-fit: cover;
  margin-bottom: 26px;
}
.our-blogs .our-blogs-container .article .art-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.our-blogs .our-blogs-container .article .art-details .art-title a {
  display: block;
  font-size: 22px;
  font-weight: 600;
  line-height: 1.25;
  margin: 0 0 17px 0;
  letter-spacing: 0.52px;
  color: #1B1819;
  text-align: center;
}
.our-blogs .our-blogs-container .article .art-details .art-title a:hover {
  color: #8f6B29;
}

@media screen and (max-width: 480px) {
  .our-blogs .section-title h2 {
    font-size: 36px;
  }
  .our-blogs .our-blogs-container {
    flex-wrap: wrap;
  }
  .our-blogs .our-blogs-container .article {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 20px;
  }
  .our-blogs .our-blogs-container .article:last-of-type {
    margin-bottom: 0;
  }
}
.login-register-page .page-intro {
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.login-register-page .page-intro .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
}
.login-register-page .page-intro .rec {
  position: absolute;
  top: -184px;
  right: 120px;
  width: 368px;
  height: 368px;
  border-radius: 50%;
  opacity: 0.3;
  background: rgba(0, 0, 0, 0.93);
}
.login-register-page .page-intro .rec.rec-bottom {
  top: unset;
  bottom: -120px;
  left: 0;
  background: rgba(0, 0, 0, 0.93);
}
.login-register-page .page-intro .container {
  width: 100%;
}
.login-register-page .page-intro .container .page-intro-container {
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.login-register-page .page-intro .container .page-intro-container .page-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.login-register-page .page-intro .container .page-intro-container .page-navigation a,
.login-register-page .page-intro .container .page-intro-container .page-navigation span {
  display: block;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.login-register-page .page-intro .container .page-intro-container .page-navigation a.page-name,
.login-register-page .page-intro .container .page-intro-container .page-navigation span.page-name {
  color: #8f6B29;
}
.login-register-page .page-intro .container .page-intro-container .page-title {
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  text-align: center;
}
.login-register-page .page-intro::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 50px;
  background-image: url(../images/imgs/banner-bg-shape.png);
  background-repeat: repeat-x;
  background-position: center;
}
.login-register-page .login-container {
  width: 50%;
  margin: auto;
  margin-top: 80px;
  box-shadow: 0px 30px 70px rgba(1, 15, 28, 0.1);
  padding: 50px 60px 70px;
  background-color: #fff;
}
.login-register-page .login-container .title-box {
  text-align: center;
}
.login-register-page .login-container .title-box h3 {
  font-weight: 600;
  font-size: 28px;
  margin-bottom: 4px;
  line-height: 1.2;
}
.login-register-page .login-container .title-box p {
  font-size: 16px;
  font-weight: 400;
  color: #82848B;
  margin-bottom: 15px;
  line-height: 26px;
}
.login-register-page .login-container .title-box p a {
  color: #8f6B29;
}
.login-register-page .login-container .msg-box {
  width: 100%;
}
.login-register-page .login-container .msg-box .err, .login-register-page .login-container .msg-box .success {
  display: flex;
  align-items: center;
  background-color: #f74f4f;
  padding: 10px;
  margin-bottom: 10px;
}
.login-register-page .login-container .msg-box .err small, .login-register-page .login-container .msg-box .success small {
  font-size: 18px;
  font-weight: 600;
  color: rgb(112, 9, 9);
  display: inline-block;
  margin-right: 5px;
}
.login-register-page .login-container .msg-box .err p, .login-register-page .login-container .msg-box .success p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}
.login-register-page .login-container .msg-box .err.success, .login-register-page .login-container .msg-box .success.success {
  background-color: rgb(101, 184, 101);
}
.login-register-page .login-container .msg-box .err.success small, .login-register-page .login-container .msg-box .success.success small {
  color: rgb(11, 68, 11);
}
.login-register-page .login-container .form {
  width: 90%;
  margin: auto;
  margin-top: 30px;
  display: block;
}
.login-register-page .login-container .form .field {
  margin-bottom: 20px;
}
.login-register-page .login-container .form .field label {
  display: block;
  margin-bottom: 10px;
  color: #82848B;
  font-size: 16px;
  font-weight: 400;
}
.login-register-page .login-container .form .field input {
  outline: none;
  background-color: #fff;
  height: 56px;
  width: 100%;
  line-height: 56px;
  font-size: 14px;
  color: #1B1819;
  padding-left: 26px;
  padding-right: 26px;
  border: 1px solid #e0e2e3;
  border-radius: 10px;
}
.login-register-page .login-container .form .field .forget-password {
  display: block;
  margin-top: 20px;
  text-align: right;
  font-size: 16px;
  color: #8f6B29;
}
.login-register-page .login-container .form .submit {
  display: block;
  width: 100%;
  border: none;
  background-color: transparent;
  width: 100%;
  background-color: #8f6B29;
  border: 1px solid #8f6B29;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  border-radius: 50px;
  text-align: center;
  text-transform: capitalize;
  line-height: 1;
  padding: 22px 41px;
  cursor: pointer;
  transition: all ease-in-out 0.5s;
}
.login-register-page .login-container .form .submit:hover {
  background-color: #fff;
  border-color: #8f6B29;
  color: #8f6B29;
}

@media screen and (max-width: 480px) {
  .login-register-page .page-intro .rec-bottom {
    bottom: -200px !important;
  }
  .login-register-page .login-container {
    width: 95%;
    padding: 50px 20px 70px;
  }
  .login-register-page .login-container .title-box h3 {
    font-size: 30px;
  }
  .login-register-page .login-container .form {
    width: 100%;
  }
}
.profile-page .page-intro {
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.profile-page .page-intro .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
}
.profile-page .page-intro .rec {
  position: absolute;
  top: -184px;
  right: 120px;
  width: 368px;
  height: 368px;
  border-radius: 50%;
  opacity: 0.3;
  background: rgba(0, 0, 0, 0.93);
}
.profile-page .page-intro .rec.rec-bottom {
  top: unset;
  bottom: -120px;
  left: 0;
  background: rgba(0, 0, 0, 0.93);
}
.profile-page .page-intro .container {
  width: 100%;
}
.profile-page .page-intro .container .page-intro-container {
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.profile-page .page-intro .container .page-intro-container .page-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.profile-page .page-intro .container .page-intro-container .page-navigation a,
.profile-page .page-intro .container .page-intro-container .page-navigation span {
  display: block;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.profile-page .page-intro .container .page-intro-container .page-navigation a.page-name,
.profile-page .page-intro .container .page-intro-container .page-navigation span.page-name {
  color: #8f6B29;
}
.profile-page .page-intro .container .page-intro-container .page-title {
  width: 80%;
  margin: auto;
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  text-align: center;
}
.profile-page .page-intro::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 50px;
  background-image: url(../images/imgs/banner-bg-shape.png);
  background-repeat: repeat-x;
  background-position: center;
}
.profile-page .profile-container {
  margin-top: 80px;
}
.profile-page .profile-container h2 {
  font-size: 36px;
  font-weight: 600;
  text-align: center;
  color: #1B1819;
}
.profile-page .profile-container .profile-wraper {
  width: 85%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.profile-page .profile-container .profile-wraper .wishlist {
  width: 49%;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #8f6B29;
  border-radius: 5px;
  padding: 20px;
}
.profile-page .profile-container .profile-wraper .wishlist h4 {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-page .profile-container .profile-wraper .wishlist h4 img {
  width: 25px;
  margin-right: 5px;
}
.profile-page .profile-container .profile-wraper .wishlist .no-items {
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper .fav-product {
  width: 100%;
  padding: 30px 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  background-color: rgb(240, 247, 243);
  position: relative;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper .fav-product .trash {
  position: absolute;
  top: 8px;
  right: 8px;
  padding: 5px;
  width: 35px;
  cursor: pointer;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper .fav-product .product-img {
  width: 100px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper .fav-product .product-img img {
  width: 80%;
  -o-object-fit: cover;
     object-fit: cover;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper .fav-product .product-details {
  margin-left: 20px;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper .fav-product .product-details h3 {
  font-size: 16px;
  font-weight: 600;
  color: #1B1819;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper .fav-product .product-details .product-price {
  display: flex;
  justify-content: center;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper .fav-product .product-details .product-price p {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bold;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper .fav-product .product-details .product-price p.old-price {
  color: #999;
  text-decoration: line-through;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper .fav-product .product-details .add-to-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #8f6B29;
  padding: 5px;
  margin-top: 15px;
  cursor: pointer;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper .fav-product .product-details .add-to-cart h5 {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
.profile-page .profile-container .profile-wraper .wishlist .product-wraper .fav-product .product-details .add-to-cart img {
  width: 25px;
  margin-right: 5px;
}
.profile-page .profile-container .profile-wraper .orders {
  width: 49%;
  height: -moz-fit-content;
  height: fit-content;
  border: 1px solid #8f6B29;
  border-radius: 5px;
  padding: 10px;
}
.profile-page .profile-container .profile-wraper .orders h4 {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-page .profile-container .profile-wraper .orders h4 img {
  width: 25px;
  margin-right: 5px;
}
.profile-page .profile-container .profile-wraper .orders .no-items {
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order {
  width: 100%;
  padding: 10px;
  border-radius: 20px;
  background-color: rgb(221, 221, 228);
  margin-bottom: 10px;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .order-number {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .order-number small {
  font-size: 24px;
  margin-right: auto;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .products-wraper {
  width: 100%;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .products-wraper .product-order {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .products-wraper .product-order .product-order-details {
  width: 100%;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .products-wraper .product-order .product-order-details .name-size h3 {
  font-size: 16px;
  color: #1B1819;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .products-wraper .product-order .product-order-details .name-size p {
  font-size: 16px;
  text-align: center;
  color: #1B1819;
  text-transform: uppercase;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .products-wraper .product-order .product-order-details .product-price p {
  font-size: 18px;
  color: #1B1819;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .products-wraper .product-order .product-img {
  width: 80px;
  height: 100px;
  background-color: #999;
  border-radius: 10px;
  padding: 5px;
  position: relative;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .products-wraper .product-order .product-img span {
  width: 20px;
  height: 20px;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color: #fff;
  position: absolute;
  top: -5px;
  left: 10px;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .products-wraper .product-order .product-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .order-status {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .order-status p {
  width: 31.6666666667%;
  font-size: 16px;
  text-align: center;
}
.profile-page .profile-container .profile-wraper .orders .orders-wraper .order .order-status p.price {
  font-size: 18px;
}

@media screen and (max-width: 576px) {
  .profile-page .profile-container .profile-wraper {
    width: 98%;
    flex-direction: column-reverse;
  }
  .profile-page .profile-container .profile-wraper .wishlist {
    width: 100%;
    flex-shrink: 0;
    margin-bottom: 30px;
  }
  .profile-page .profile-container .profile-wraper .orders {
    width: 100%;
    flex-shrink: 0;
  }
}
.cart-page .page-intro {
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.cart-page .page-intro .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
}
.cart-page .page-intro .rec {
  position: absolute;
  top: -184px;
  right: 120px;
  width: 368px;
  height: 368px;
  border-radius: 50%;
  opacity: 0.3;
  background: rgba(0, 0, 0, 0.93);
}
.cart-page .page-intro .rec.rec-bottom {
  top: unset;
  bottom: -120px;
  left: 0;
  background: rgba(0, 0, 0, 0.93);
}
.cart-page .page-intro .container {
  width: 100%;
}
.cart-page .page-intro .container .page-intro-container {
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cart-page .page-intro .container .page-intro-container .page-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.cart-page .page-intro .container .page-intro-container .page-navigation a,
.cart-page .page-intro .container .page-intro-container .page-navigation span {
  display: block;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.cart-page .page-intro .container .page-intro-container .page-navigation a.page-name,
.cart-page .page-intro .container .page-intro-container .page-navigation span.page-name {
  color: #8f6B29;
}
.cart-page .page-intro .container .page-intro-container .page-title {
  width: 80%;
  margin: auto;
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  text-align: center;
}
.cart-page .page-intro::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 50px;
  background-image: url(../images/imgs/banner-bg-shape.png);
  background-repeat: repeat-x;
  background-position: center;
}
.cart-page .cart-container {
  width: 85%;
  margin: auto;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}
.cart-page .cart-container .cart-items {
  width: 72%;
}
.cart-page .cart-container .cart-items .cart-titles {
  list-style: none;
  display: flex;
  border-bottom: 1px solid #EAEAEA;
}
.cart-page .cart-container .cart-items .cart-titles li {
  color: #1B1819;
  font-size: 16px;
  line-height: 30px;
  font-weight: 600;
  padding: 0 0 24px;
  text-transform: uppercase;
}
.cart-page .cart-container .cart-items .cart-titles li.items {
  width: 50%;
}
.cart-page .cart-container .cart-items .cart-titles .titles-group {
  display: flex;
  flex-grow: 1;
}
.cart-page .cart-container .cart-items .cart-titles .titles-group li.price {
  margin-right: 70px;
}
.cart-page .cart-container .cart-items .cart-titles .titles-group li.total {
  margin-left: auto !important;
  margin-right: 20px !important;
}
.cart-page .cart-container .cart-items .items-wraper {
  margin-top: 20px;
}
.cart-page .cart-container .cart-items .items-wraper .item {
  display: flex;
  align-items: center;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid #EAEAEA;
}
.cart-page .cart-container .cart-items .items-wraper .item .item-img {
  display: flex;
  align-items: center;
}
.cart-page .cart-container .cart-items .items-wraper .item .item-img .img {
  width: 120px;
  height: 120px;
  background-color: #F9F9F9;
  border: 1px solid #EAEAEA;
  margin-right: 34px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart-page .cart-container .cart-items .items-wraper .item .item-img .img img {
  display: block;
  width: 70px;
}
.cart-page .cart-container .cart-items .items-wraper .item .item-img h4 {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
  color: #1B1819;
}
.cart-page .cart-container .cart-items .items-wraper .item .item-details {
  width: 50%;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.cart-page .cart-container .cart-items .items-wraper .item .item-details p {
  font-size: 18px;
  font-weight: 600;
  color: #82848B;
}
.cart-page .cart-container .cart-items .items-wraper .item .item-details .qty {
  display: flex;
  align-items: center;
}
.cart-page .cart-container .cart-items .items-wraper .item .item-details .qty .qty-input {
  display: flex;
  align-items: center;
}
.cart-page .cart-container .cart-items .items-wraper .item .item-details .qty .qty-input span {
  display: block;
  font-size: 22px;
  font-weight: 600;
  padding: 5px;
  cursor: pointer;
  color: #1B1819;
}
.cart-page .cart-container .cart-items .items-wraper .item .item-details .qty .qty-input input {
  width: 70px;
  height: 40px;
  display: block;
  margin: 0 5px;
  text-align: center;
  line-height: 50px;
  border: 1px solid #eaeaea;
  color: #82848B;
  font-size: 18px;
  font-weight: 500;
  outline: none;
}
.cart-page .cart-container .cart-items .items-wraper .item .item-details .remove {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.cart-page .cart-container .cart-details {
  width: 23%;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #F9F9F9;
  padding: 30px 20px;
}
.cart-page .cart-container .cart-details h3 {
  font-size: 18px;
  font-weight: 600;
  color: #1B1819;
  padding-bottom: 13px;
  margin-bottom: 19px;
  border-bottom: 1px solid #EAEAEA;
}
.cart-page .cart-container .cart-details .total {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cart-page .cart-container .cart-details .total h6, .cart-page .cart-container .cart-details .total span {
  font-size: 18px;
  font-weight: 600;
  color: #1B1819;
}
.cart-page .cart-container .cart-details .total.discount {
  background-color: rgb(13, 179, 77);
  padding: 5px;
  margin-top: 10px;
}
.cart-page .cart-container .cart-details .total.discount h6, .cart-page .cart-container .cart-details .total.discount span {
  color: #FFF;
}
.cart-page .cart-container .cart-details .total.expire {
  background-color: rgb(209, 36, 51);
  padding: 5px;
  margin-top: 10px;
}
.cart-page .cart-container .cart-details .total.expire span {
  font-size: 16px;
  font-size: 500;
  text-align: center;
  color: #FFF;
}
.cart-page .cart-container .cart-details .cupon-box {
  margin-top: 20px;
}
.cart-page .cart-container .cart-details .cupon-box input {
  display: block;
  width: 100%;
  padding: 15px 5px;
  border: 1px solid #EAEAEA;
  font-size: 14px;
  background-color: #fff;
  outline: none;
}
.cart-page .cart-container .cart-details .cupon-box button {
  width: 40%;
  display: block;
  padding: 10px 0;
  margin-top: 10px;
  margin-left: auto;
  border: none;
  background-color: #8f6B29;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
}
.cart-page .cart-container .cart-details .checkout-box {
  margin-top: 40px;
}
.cart-page .cart-container .cart-details .checkout-box a {
  display: block;
  padding: 10px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  background-color: #1B1819;
  color: #fff;
  margin-bottom: 15px;
}
.cart-page .empty {
  text-align: center;
  margin-top: 50px;
}
.cart-page .empty p {
  width: 90%;
  margin: auto;
  font-size: 18px;
  font-weight: 600;
  color: #1B1819;
}
.cart-page .empty a {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 10px;
  padding: 10px;
  background-color: rgb(14, 173, 67);
  color: #FFF;
  font-size: 16px;
  font-weight: 600;
}

@media screen and (max-width: 480px) {
  .cart-page .page-intro .rec-bottom {
    bottom: -200px !important;
  }
  .cart-page .cart-container {
    width: 100%;
    flex-direction: column;
  }
  .cart-page .cart-container .cart-items {
    width: 100%;
  }
  .cart-page .cart-container .cart-items .cart-titles {
    display: none;
  }
  .cart-page .cart-container .cart-items .items-wraper {
    width: 100%;
  }
  .cart-page .cart-container .cart-items .items-wraper .item {
    width: 100%;
    padding: 10px;
    flex-direction: column;
  }
  .cart-page .cart-container .cart-items .items-wraper .item .item-img {
    width: 100%;
  }
  .cart-page .cart-container .cart-items .items-wraper .item .item-details {
    width: 90%;
    margin: auto;
    margin-top: 20px;
  }
  .cart-page .cart-container .cart-details {
    width: 100%;
  }
}
.checkout-page .page-intro {
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.checkout-page .page-intro .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
}
.checkout-page .page-intro .rec {
  position: absolute;
  top: -184px;
  right: 120px;
  width: 368px;
  height: 368px;
  border-radius: 50%;
  opacity: 0.3;
  background: rgba(0, 0, 0, 0.93);
}
.checkout-page .page-intro .rec.rec-bottom {
  top: unset;
  bottom: -120px;
  left: 0;
  background: rgba(0, 0, 0, 0.93);
}
.checkout-page .page-intro .container {
  width: 100%;
}
.checkout-page .page-intro .container .page-intro-container {
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.checkout-page .page-intro .container .page-intro-container .page-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.checkout-page .page-intro .container .page-intro-container .page-navigation a,
.checkout-page .page-intro .container .page-intro-container .page-navigation span {
  display: block;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}
.checkout-page .page-intro .container .page-intro-container .page-navigation a.page-name,
.checkout-page .page-intro .container .page-intro-container .page-navigation span.page-name {
  color: #8f6B29;
}
.checkout-page .page-intro .container .page-intro-container .page-title {
  width: 80%;
  margin: auto;
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  text-align: center;
}
.checkout-page .page-intro::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 50px;
  background-image: url(../images/imgs/banner-bg-shape.png);
  background-repeat: repeat-x;
  background-position: center;
}
.checkout-page .checkout-container {
  width: 85%;
  margin: auto;
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
}
.checkout-page .checkout-container .shipping-info {
  width: 58%;
  padding: 20px;
  background-color: #f9f9f9;
}
.checkout-page .checkout-container .shipping-info h2 {
  font-size: 24px;
  font-weight: 600;
  color: #1B1819;
}
.checkout-page .checkout-container .shipping-info .address,
.checkout-page .checkout-container .shipping-info .address-details,
.checkout-page .checkout-container .shipping-info .phone,
.checkout-page .checkout-container .shipping-info .city,
.checkout-page .checkout-container .shipping-info .email {
  margin-top: 20px;
}
.checkout-page .checkout-container .shipping-info .address label,
.checkout-page .checkout-container .shipping-info .address-details label,
.checkout-page .checkout-container .shipping-info .phone label,
.checkout-page .checkout-container .shipping-info .city label,
.checkout-page .checkout-container .shipping-info .email label {
  display: block;
  font-size: 15px;
  font-weight: 500;
  color: #82848B;
  margin-bottom: 10px;
}
.checkout-page .checkout-container .shipping-info .address select,
.checkout-page .checkout-container .shipping-info .address input,
.checkout-page .checkout-container .shipping-info .address-details select,
.checkout-page .checkout-container .shipping-info .address-details input,
.checkout-page .checkout-container .shipping-info .phone select,
.checkout-page .checkout-container .shipping-info .phone input,
.checkout-page .checkout-container .shipping-info .city select,
.checkout-page .checkout-container .shipping-info .city input,
.checkout-page .checkout-container .shipping-info .email select,
.checkout-page .checkout-container .shipping-info .email input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  outline: none;
  font-size: 15px;
  font-weight: 500;
  color: #82848B;
}
.checkout-page .checkout-container .shipping-info .address select option,
.checkout-page .checkout-container .shipping-info .address input option,
.checkout-page .checkout-container .shipping-info .address-details select option,
.checkout-page .checkout-container .shipping-info .address-details input option,
.checkout-page .checkout-container .shipping-info .phone select option,
.checkout-page .checkout-container .shipping-info .phone input option,
.checkout-page .checkout-container .shipping-info .city select option,
.checkout-page .checkout-container .shipping-info .city input option,
.checkout-page .checkout-container .shipping-info .email select option,
.checkout-page .checkout-container .shipping-info .email input option {
  font-size: 16px;
  color: #82848B;
}
.checkout-page .checkout-container .shipping-info .address .add-address,
.checkout-page .checkout-container .shipping-info .address-details .add-address,
.checkout-page .checkout-container .shipping-info .phone .add-address,
.checkout-page .checkout-container .shipping-info .city .add-address,
.checkout-page .checkout-container .shipping-info .email .add-address {
  font-size: 15px;
  font-weight: 500;
  color: #8f6B29;
  display: block;
  margin-top: 5px;
}
.checkout-page .checkout-container .shipping-info .address .add-address:hover,
.checkout-page .checkout-container .shipping-info .address-details .add-address:hover,
.checkout-page .checkout-container .shipping-info .phone .add-address:hover,
.checkout-page .checkout-container .shipping-info .city .add-address:hover,
.checkout-page .checkout-container .shipping-info .email .add-address:hover {
  text-decoration: underline;
}
.checkout-page .checkout-container .shipping-info .name {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.checkout-page .checkout-container .shipping-info .name input {
  width: 49%;
  padding: 15px;
  border: 1px solid #ccc;
  outline: none;
  text-align: right;
  font-size: 16px;
}
.checkout-page .checkout-container .shipping-info .send-order-msg {
  width: 100%;
  padding: 15px 10px;
  background-color: #75b798;
  justify-content: center;
  margin-top: 20px;
}
.checkout-page .checkout-container .shipping-info .send-order-msg h5 {
  margin-left: 10px;
  color: #051b11;
  font-size: 16px;
}
.checkout-page .checkout-container .shipping-info .send-order-msg p {
  font-size: 16px;
}
.checkout-page .checkout-container .shipping-info .complete-order {
  width: 100%;
  margin: auto;
  margin-top: 30px;
}
.checkout-page .checkout-container .shipping-info .complete-order a {
  display: block;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  background-color: #8f6B29;
  color: #fff;
}
.checkout-page .checkout-container .cart-items {
  width: 40%;
  height: 300px;
  height: 100%;
  padding: 20px;
  text-align: right;
  background-color: #F9F9F9;
}
.checkout-page .checkout-container .cart-items h3 {
  font-size: 24px;
  font-weight: 600;
  color: #1B1819;
  text-align: left;
  margin-bottom: 30px;
}
.checkout-page .checkout-container .cart-items .cart-items-wraper {
  width: 100%;
}
.checkout-page .checkout-container .cart-items .cart-items-wraper .cart-item {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
}
.checkout-page .checkout-container .cart-items .cart-items-wraper .cart-item .product-img {
  position: relative;
  width: 80px;
  height: 100px;
  background-color: #ccc;
  padding: 5px;
  border-radius: 10px;
  text-align: center;
}
.checkout-page .checkout-container .cart-items .cart-items-wraper .cart-item .product-img span {
  width: 20px;
  height: 20px;
  position: absolute;
  top: -5px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 12px;
  font-weight: 600;
  background-color: #333;
  color: #fff;
  border-radius: 50%;
}
.checkout-page .checkout-container .cart-items .cart-items-wraper .cart-item .product-img img {
  width: 85%;
  -o-object-fit: cover;
     object-fit: cover;
}
.checkout-page .checkout-container .cart-items .cart-items-wraper .cart-item .product-details {
  flex-grow: 1;
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.checkout-page .checkout-container .cart-items .cart-items-wraper .cart-item .product-details .name-size h4 {
  font-size: 16px;
  font-weight: 500;
}
.checkout-page .checkout-container .cart-items .cart-items-wraper .cart-item .product-details .name-size p {
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
}
.checkout-page .checkout-container .cart-items .cart-items-wraper .cart-item .product-details .price {
  margin-left: auto;
}
.checkout-page .checkout-container .cart-items .cart-items-wraper .cart-item .product-details .price p {
  font-size: 16px;
  font-weight: 500;
}
.checkout-page .checkout-container .cart-items .price-details {
  margin-top: 50px;
}
.checkout-page .checkout-container .cart-items .price-details span {
  display: flex;
  align-items: center;
}
.checkout-page .checkout-container .cart-items .price-details span h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.checkout-page .checkout-container .cart-items .price-details span p {
  font-size: 16px;
  font-weight: 500;
  margin-left: auto;
}
.checkout-page .checkout-container .cart-items .price-details span.total-after-discount {
  margin-top: 20px;
}

@media screen and (max-width: 480px) {
  .checkout-page .page-intro .rec-bottom {
    bottom: -200px !important;
  }
  .checkout-page .page-intro .container .page-intro-container {
    width: 95%;
  }
  .checkout-page .page-intro .container .page-intro-container .page-title {
    width: 95%;
    font-size: 55px;
  }
  .checkout-page .checkout-container {
    width: 95%;
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
  .checkout-page .checkout-container .shipping-info {
    flex-shrink: 0;
    width: 100%;
  }
  .checkout-page .checkout-container .cart-items {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 30px;
  }
}
.order-details-page {
  padding-top: 180px;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
}
.order-details-page .container .order-wraper {
  width: 100%;
}
.order-details-page .container .order-wraper h2 {
  font-size: 30px;
  color: #FFF;
  font-weight: 500;
  text-align: center;
}
.order-details-page .container .order-wraper .order-details-container {
  padding: 10px;
  margin: 30px 0;
  border-radius: 20px;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
  display: flex;
  justify-content: space-between;
}
.order-details-page .container .order-wraper .order-details-container .order-details {
  width: 45%;
}
.order-details-page .container .order-wraper .order-details-container .order-details .user-info,
.order-details-page .container .order-wraper .order-details-container .order-details .shipping-info,
.order-details-page .container .order-wraper .order-details-container .order-details .order-status {
  margin-bottom: 30px;
}
.order-details-page .container .order-wraper .order-details-container .order-details .user-info h3,
.order-details-page .container .order-wraper .order-details-container .order-details .shipping-info h3,
.order-details-page .container .order-wraper .order-details-container .order-details .order-status h3 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 5px 0 5px 10px;
  background: -webkit-linear-gradient(left, #a18755, #8f6B29);
  border-radius: 20px;
  color: #FFF;
}
.order-details-page .container .order-wraper .order-details-container .order-details .user-info ul,
.order-details-page .container .order-wraper .order-details-container .order-details .shipping-info ul,
.order-details-page .container .order-wraper .order-details-container .order-details .order-status ul {
  padding-left: 15px;
}
.order-details-page .container .order-wraper .order-details-container .order-details .user-info ul li,
.order-details-page .container .order-wraper .order-details-container .order-details .shipping-info ul li,
.order-details-page .container .order-wraper .order-details-container .order-details .order-status ul li {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  color: #FFF;
}
.order-details-page .container .order-wraper .order-details-container .order-details .user-info ul li:last-of-type,
.order-details-page .container .order-wraper .order-details-container .order-details .shipping-info ul li:last-of-type,
.order-details-page .container .order-wraper .order-details-container .order-details .order-status ul li:last-of-type {
  margin-bottom: 0;
}
.order-details-page .container .order-wraper .order-details-container .order-details .user-info ul li p,
.order-details-page .container .order-wraper .order-details-container .order-details .shipping-info ul li p,
.order-details-page .container .order-wraper .order-details-container .order-details .order-status ul li p {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}
.order-details-page .container .order-wraper .order-details-container .order-details .user-info ul.address-list,
.order-details-page .container .order-wraper .order-details-container .order-details .shipping-info ul.address-list,
.order-details-page .container .order-wraper .order-details-container .order-details .order-status ul.address-list {
  background-color: #121212;
  padding: 5px;
  border-radius: 5px;
  margin-top: 10px;
}
.order-details-page .container .order-wraper .order-details-container .order-details .user-info .no-item,
.order-details-page .container .order-wraper .order-details-container .order-details .shipping-info .no-item,
.order-details-page .container .order-wraper .order-details-container .order-details .order-status .no-item {
  color: #FFF;
  padding-left: 10px;
}
.order-details-page .container .order-wraper .order-details-container .order-items {
  width: 53%;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
}
.order-details-page .container .order-wraper .order-details-container .order-items .order-products-wraper {
  width: 100%;
}
.order-details-page .container .order-wraper .order-details-container .order-items .order-products-wraper .product-cart {
  display: flex;
  align-items: center;
  background: -webkit-linear-gradient(left, #a18755, #8f6B29);
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 10px;
  width: 100%;
}
.order-details-page .container .order-wraper .order-details-container .order-items .order-products-wraper .product-cart .prodeuct-details {
  margin-left: 20px;
}
.order-details-page .container .order-wraper .order-details-container .order-items .order-products-wraper .product-cart .prodeuct-details ul li {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #FFF;
}
.order-details-page .container .order-wraper .order-details-container .order-items .order-products-wraper .product-cart .prodeuct-details ul li:last-of-type {
  margin-bottom: 0;
}
.order-details-page .container .order-wraper .order-details-container .order-items .order-products-wraper .product-cart .prodeuct-details ul li p {
  font-size: 16px;
  text-align: center;
  margin-left: 10px;
}
.order-details-page .container .order-wraper .order-details-container .order-items .order-products-wraper .product-cart .prodeuct-details ul li p.size {
  text-transform: uppercase;
}
.order-details-page .container .order-wraper .order-details-container .order-items .order-products-wraper .product-cart .product-img {
  width: 80px;
  height: 100px;
  padding: 5px;
  background-color: #333;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.order-details-page .container .order-wraper .order-details-container .order-items .order-products-wraper .product-cart .product-img img {
  width: 90%;
  border-radius: 5px;
}

.profile-navigation {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;
  flex-wrap: wrap;
  list-style: none;
}
.profile-navigation li a {
  display: block;
  padding: 10px 20px;
  background-color: #8f6B29;
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  margin-left: 10px;
  flex-shrink: 0;
  margin-top: 5px;
}

.add-address-page {
  padding-top: 180px;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
}
.add-address-page h2 {
  text-align: center;
  font-size: 36px;
  font-weight: 600;
  color: #FFF;
}
.add-address-page .add-address-containers {
  width: 90%;
  margin: auto;
  margin-top: 30px;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
  padding: 20px;
  border-radius: 20px;
}
.add-address-page .add-address-containers .msg-wraper {
  width: 60%;
  padding: 15px 10px;
  text-align: center;
  justify-content: center;
  background-color: #75b798;
  display: none;
  margin: auto;
  margin-top: 20px;
}
.add-address-page .add-address-containers .msg-wraper h5 {
  margin-left: 5px;
  color: #051B11;
  font-size: 16px;
}
.add-address-page .add-address-containers .msg-wraper p {
  font-size: 16px;
}
.add-address-page .add-address-containers h3 {
  text-align: center;
  color: #FFF;
  font-size: 24px;
}
.add-address-page .add-address-containers form {
  width: 100%;
  margin-top: 30px;
}
.add-address-page .add-address-containers form input {
  display: block;
  width: 80%;
  padding: 20px 10px;
  margin: auto;
  border-radius: 10px;
  background-color: #111;
  color: #FFF;
  font-size: 16px;
  border: none;
  outline: none;
  margin-bottom: 15px;
}
.add-address-page .add-address-containers form input.submit {
  background: -webkit-linear-gradient(left, #a18755, #8f6B29);
  color: #FFF;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.add-address-page .add-address-containers .adresses-wraper {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.add-address-page .add-address-containers .adresses-wraper .no-items {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #FFF;
}
.add-address-page .add-address-containers .adresses-wraper .address {
  display: flex;
  position: relative;
  width: 100%;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
  background: -webkit-linear-gradient(left, #a18755, #8f6B29);
}
.add-address-page .add-address-containers .adresses-wraper .address .trash-img {
  width: 40px;
  height: 40px;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  right: 19px;
  top: 10px;
}
.add-address-page .add-address-containers .adresses-wraper .address .home-img {
  width: 159px;
  height: 150px;
  padding: 10px;
  display: block;
  border-radius: 20px;
  background-color: #333;
}
.add-address-page .add-address-containers .adresses-wraper .address .address-details {
  margin-left: 20px;
  padding: 20px 0;
}
.add-address-page .add-address-containers .adresses-wraper .address .address-details span {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.add-address-page .add-address-containers .adresses-wraper .address .address-details span:last-of-type {
  margin-bottom: 0;
}
.add-address-page .add-address-containers .adresses-wraper .address .address-details span h5 {
  font-size: 16px;
  font-weight: 600;
  color: #1B1819;
}
.add-address-page .add-address-containers .adresses-wraper .address .address-details span p {
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
  color: #FFF;
}

@media screen and (max-width: 576px) {
  .add-address-page .add-address-containers {
    width: 98%;
  }
  .add-address-page .add-address-containers .msg-wraper {
    width: 95%;
  }
  .add-address-page .add-address-containers form input {
    width: 100%;
  }
  .add-address-page .add-address-containers .adresses-wraper .address .home-img {
    display: none;
  }
}
.single-blog-page .single-blog-intro {
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}
.single-blog-page .single-blog-intro .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.5) 11.2%, rgba(63, 61, 61, 0.5) 78.9%);
}
.single-blog-page .single-blog-intro .rec {
  position: absolute;
  top: -184px;
  right: 120px;
  width: 368px;
  height: 368px;
  border-radius: 50%;
  opacity: 0.3;
  background: rgba(0, 0, 0, 0.93);
}
.single-blog-page .single-blog-intro .rec.rec-bottom {
  top: unset;
  bottom: -120px;
  left: 0;
  background: rgba(0, 0, 0, 0.93);
}
.single-blog-page .single-blog-intro .container {
  width: 100%;
}
.single-blog-page .single-blog-intro .container .blog-intro-container {
  width: 80%;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.single-blog-page .single-blog-intro .container .blog-intro-container .page-navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.single-blog-page .single-blog-intro .container .blog-intro-container .page-navigation a, .single-blog-page .single-blog-intro .container .blog-intro-container .page-navigation span {
  display: block;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #FFF;
}
.single-blog-page .single-blog-intro .container .blog-intro-container .page-navigation a.blog-details, .single-blog-page .single-blog-intro .container .blog-intro-container .page-navigation span.blog-details {
  color: #8f6B29;
}
.single-blog-page .single-blog-intro .container .blog-intro-container .blog-title {
  width: 80%;
  margin: auto;
  text-transform: uppercase;
  font-size: 60px;
  font-weight: 600;
  line-height: 1.2;
  color: #FFF;
  text-align: center;
}
.single-blog-page .single-blog-intro::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -7px;
  width: 100%;
  height: 50px;
  background-image: url(../images/imgs/banner-bg-shape.png);
  background-repeat: repeat-x;
  background-position: center;
}
.single-blog-page .content {
  width: 68%;
  margin: auto;
  margin-top: 80px;
}
.single-blog-page .content .article-img {
  width: 100%;
  height: 395px;
  border-radius: 20px;
  position: relative;
}
.single-blog-page .content .article-img .overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: rgba(0, 0, 0, 0.1);
}
.single-blog-page .content .article-img .thum-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 20px;
}
.single-blog-page .content .article-img .article-details {
  width: 100%;
  border-radius: 20px;
  background-color: #FFF;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 18px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(202, 202, 202, 0.25) 0px 9px 16px;
}
.single-blog-page .content .article-img .article-details .author-img-box {
  display: flex;
  align-items: center;
}
.single-blog-page .content .article-img .article-details .author-img-box .author-img {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  margin-right: 15px;
}
.single-blog-page .content .article-img .article-details .author-img-box h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #1B1819;
}
.single-blog-page .content .article-img .article-details .date-box, .single-blog-page .content .article-img .article-details .comments-box, .single-blog-page .content .article-img .article-details .views-box {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.single-blog-page .content .article-img .article-details .date-box img, .single-blog-page .content .article-img .article-details .comments-box img, .single-blog-page .content .article-img .article-details .views-box img {
  width: 25px;
  height: 25px;
}
.single-blog-page .content .article-img .article-details .date-box h6, .single-blog-page .content .article-img .article-details .comments-box h6, .single-blog-page .content .article-img .article-details .views-box h6 {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #82848B;
  margin-left: 15px;
}
.single-blog-page .content .body {
  margin-top: 50px;
}
.single-blog-page .content .body h1, .single-blog-page .content .body h2, .single-blog-page .content .body h3, .single-blog-page .content .body h4, .single-blog-page .content .body h5, .single-blog-page .content .body h6 {
  font-weight: 600;
  font-size: 36px;
  line-height: 1.2;
  color: #1B1819;
  margin-bottom: 50px;
}
.single-blog-page .content .body p {
  font-size: 17px;
  font-weight: 500;
  color: #82848B;
  margin-bottom: 15px;
  line-height: 1.6;
}
.single-blog-page .content .body p a {
  color: #8f6B29;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.single-blog-page .content .body ul, .single-blog-page .content .body ol {
  padding: 0 40px;
}
.single-blog-page .content .body ul li, .single-blog-page .content .body ol li {
  font-size: 17px;
  font-weight: 500;
  color: #82848B;
  margin-bottom: 10px;
  line-height: 1.6;
}
.single-blog-page .content .body ul li a, .single-blog-page .content .body ol li a {
  color: #8f6B29;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.single-blog-page .content .body a {
  font-size: 17px;
  font-weight: 500;
  color: #8f6B29;
  margin-bottom: 10px;
  line-height: 1.6;
}

@media screen and (max-width: 480px) {
  .single-blog-page .single-blog-intro {
    height: 80vh;
  }
  .single-blog-page .single-blog-intro .rec-bottom {
    bottom: -200px !important;
  }
  .single-blog-page .single-blog-intro .container .blog-intro-container {
    width: 95%;
  }
  .single-blog-page .single-blog-intro .container .blog-intro-container .blog-title {
    width: 95%;
    font-size: 36px;
  }
  .single-blog-page .content {
    width: 95%;
  }
  .single-blog-page .content .article-img {
    height: 195px;
  }
  .single-blog-page .content .article-img .article-details {
    flex-wrap: wrap;
    bottom: -112px;
  }
  .single-blog-page .content .article-img .article-details .author-img-box {
    flex-shrink: 0;
  }
  .single-blog-page .content .article-img .article-details .date-box, .single-blog-page .content .article-img .article-details .comments-box, .single-blog-page .content .article-img .article-details .views-box {
    flex-shrink: 0;
    margin-top: 10px;
  }
  .single-blog-page .content .article-img .article-details .date-box.date-box, .single-blog-page .content .article-img .article-details .comments-box.date-box, .single-blog-page .content .article-img .article-details .views-box.date-box {
    margin-top: 0;
  }
  .single-blog-page .content .body {
    margin-top: 170px;
    padding: 0 5px;
  }
  .single-blog-page .content .body h1, .single-blog-page .content .body h2, .single-blog-page .content .body h3, .single-blog-page .content .body h4, .single-blog-page .content .body h5, .single-blog-page .content .body h6 {
    font-size: 25px;
  }
  .single-blog-page .content .body p {
    font-size: 14px;
    text-wrap: balance;
  }
  .single-blog-page .content .body ul, .single-blog-page .content .body ol {
    padding: 0 30px;
  }
  .single-blog-page .content .body ul li, .single-blog-page .content .body ol li {
    font-size: 14px;
    font-weight: 500;
    color: #82848B;
    margin-bottom: 10px;
    line-height: 1.6;
  }
  .single-blog-page .content .body a {
    font-size: 14px;
  }
}
.page-container {
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(20, 20, 20) 78.9%);
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
  padding-top: 150px;
}
.page-container .main-section {
  padding: 10px;
}
.page-container .main-section .container {
  width: 95%;
  display: flex;
  justify-content: space-between;
}
.page-container .main-section .container .latest-news-container {
  width: 68%;
  padding: 20px;
  border-radius: 20px;
  flex-shrink: 0;
  background-color: #111;
}
.page-container .main-section .container .latest-news-container .section-title h2 {
  padding: 0;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.2;
  color: #fff;
  font-family: "Noto Kufi Arabic", sans-serif;
}
.page-container .main-section .container .latest-news-container .articles-wraper {
  margin-top: 20px;
}
.page-container .main-section .container .latest-news-container .articles-wraper .main-article {
  width: 100%;
  height: 645px;
}
.page-container .main-section .container .latest-news-container .articles-wraper .main-article .article-img {
  width: 100%;
  height: 80%;
  border-radius: 10px;
  position: relative;
}
.page-container .main-section .container .latest-news-container .articles-wraper .main-article .article-img .category {
  position: absolute;
  top: 0;
  right: 15px;
  padding: 3px 5px;
  background-color: #8f6B29;
  color: #fff;
  font-size: 12px;
  text-align: center;
  font-family: "Noto Kufi Arabic", sans-serif;
}
.page-container .main-section .container .latest-news-container .articles-wraper .main-article .article-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.page-container .main-section .container .latest-news-container .articles-wraper .main-article .descbox {
  margin-top: 10px;
  padding: 10px 0;
}
.page-container .main-section .container .latest-news-container .articles-wraper .main-article .descbox .title a {
  display: block;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 800;
  color: #8f6B29;
  font-family: "Noto Kufi Arabic", sans-serif;
}
.page-container .main-section .container .latest-news-container .articles-wraper .main-article .descbox .desc p {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "Noto Kufi Arabic", sans-serif;
  color: #fff;
}
.page-container .main-section .container .latest-news-container .articles-wraper .thumbnails-wraper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
}
.page-container .main-section .container .latest-news-container .articles-wraper .thumbnails-wraper .thumbnail-article {
  width: 49%;
  height: 300px;
  margin-bottom: 120px;
  flex-shrink: 0;
}
.page-container .main-section .container .latest-news-container .articles-wraper .thumbnails-wraper .thumbnail-article .article-img {
  width: 100%;
  height: 80%;
  border-radius: 10px;
  position: relative;
}
.page-container .main-section .container .latest-news-container .articles-wraper .thumbnails-wraper .thumbnail-article .article-img .category {
  position: absolute;
  top: 0;
  right: 15px;
  padding: 3px 5px;
  background-color: #8f6B29;
  color: #fff;
  font-size: 12px;
  text-align: center;
  font-family: "Noto Kufi Arabic", sans-serif;
}
.page-container .main-section .container .latest-news-container .articles-wraper .thumbnails-wraper .thumbnail-article .article-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 10px;
}
.page-container .main-section .container .latest-news-container .articles-wraper .thumbnails-wraper .thumbnail-article .descbox {
  margin-top: 10px;
  padding: 10px 0;
}
.page-container .main-section .container .latest-news-container .articles-wraper .thumbnails-wraper .thumbnail-article .descbox .title a {
  display: block;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 800;
  color: #8f6B29;
  font-family: "Noto Kufi Arabic", sans-serif;
}
.page-container .main-section .container .latest-news-container .articles-wraper .thumbnails-wraper .thumbnail-article .descbox .desc p {
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-family: "Noto Kufi Arabic", sans-serif;
  color: #fff;
}
.page-container .main-section .container .latest-news-container .more-articles a {
  width: 100%;
  max-width: 300px;
  background-color: #000;
  color: #fff;
  padding: 5px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  margin: 20px auto;
  border-radius: 3px;
  display: block;
  font-family: "Noto Kufi Arabic", sans-serif;
}
.page-container .main-section .side-bar {
  width: 30%;
  flex-shrink: 0;
  background-color: #111;
  padding: 20px;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 20px;
}
.page-container .main-section .side-bar .section-title h2 {
  padding: 0;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.2;
  font-family: "Noto Kufi Arabic", sans-serif;
  color: #fff;
}
.page-container .main-section .side-bar .top-topics-wraper {
  margin-top: 50px;
}
.page-container .main-section .side-bar .top-topics-wraper .topic {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #444;
  padding-bottom: 5px;
  margin-bottom: 20px;
}
.page-container .main-section .side-bar .top-topics-wraper .topic:last-of-type {
  border: none;
}
.page-container .main-section .side-bar .top-topics-wraper .topic .topic-img {
  width: 100px;
  margin-left: 20px;
}
.page-container .main-section .side-bar .top-topics-wraper .topic .topic-img img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 5px;
}
.page-container .main-section .side-bar .top-topics-wraper .topic .topic-title a {
  color: #FFF;
  display: block;
  font-size: 14px;
  font-weight: bold;
  font-family: "Noto Kufi Arabic", sans-serif;
}

@media screen and (max-width: 480px) {
  .page-container {
    padding-top: 120px;
  }
  .page-container .main-section .container {
    width: 100%;
    flex-direction: column;
  }
  .page-container .main-section .container .latest-news-container {
    width: 100%;
    padding: 20px 10px;
  }
  .page-container .main-section .container .latest-news-container .articles-wraper .main-article {
    height: 300px;
    margin-bottom: 140px;
  }
  .page-container .main-section .container .latest-news-container .articles-wraper .thumbnails-wraper {
    flex-wrap: wrap;
  }
  .page-container .main-section .container .latest-news-container .articles-wraper .thumbnails-wraper .thumbnail-article {
    width: 100%;
  }
  .page-container .main-section .container .side-bar {
    width: 100%;
    margin-top: 30px;
  }
}
.footer-blog {
  width: 100%;
  background-color: #111;
  padding: 40px 0;
  margin-top: 50px;
}
.footer-blog .container .footer-container {
  display: flex;
  justify-content: space-between;
}
.footer-blog .container .footer-container .email-form {
  width: 40%;
}
.footer-blog .container .footer-container .email-form .descbox h5 {
  font-size: 17px;
  font-weight: 600;
  padding-bottom: 5px;
  font-family: "Noto Kufi Arabic", sans-serif;
  color: #fff;
}
.footer-blog .container .footer-container .email-form .descbox p {
  font-size: 12px;
  font-weight: 500;
  font-family: "Noto Kufi Arabic", sans-serif;
  color: #fff;
}
.footer-blog .container .footer-container .email-form form {
  display: flex;
  margin-top: 20px;
  width: 60%;
}
.footer-blog .container .footer-container .email-form form .email-input {
  width: 75%;
  display: block;
  padding: 10px 5px;
  border-radius: 0 3px 3px 0;
  font-weight: 12px;
  background-color: #fff;
  border: 1px solid #ccc;
  outline: none;
  font-family: "Noto Kufi Arabic", sans-serif;
}
.footer-blog .container .footer-container .email-form form .submit {
  width: 25%;
  border: none;
  background-color: #8f6B29;
  color: #fff;
  font-size: 12px;
  font-family: "Noto Kufi Arabic", sans-serif;
  border-radius: 3px 0 0 3px;
  cursor: pointer;
}
.footer-blog .container .footer-container .contact-us {
  width: 30%;
}
.footer-blog .container .footer-container .contact-us .descbox h5 {
  font-size: 17px;
  font-weight: 600;
  padding-bottom: 5px;
  font-family: "Noto Kufi Arabic", sans-serif;
  color: #fff;
}
.footer-blog .container .footer-container .contact-us .descbox .whats-telegram {
  display: flex;
  margin-top: 5px;
}
.footer-blog .container .footer-container .contact-us .descbox .whats-telegram .contact a {
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: #fff;
}
.footer-blog .container .footer-container .contact-us .descbox .whats-telegram .contact a img {
  width: 25px;
  height: 25px;
  margin-left: 5px;
}
.footer-blog .container .footer-container .contact-us .descbox .whats-telegram .contact a small {
  font-size: 12px;
  font-weight: 500;
  font-family: "Noto Kufi Arabic", sans-serif;
}
.footer-blog .container .footer-container .contact-us .descbox .whats-telegram .contact.whatsapp small {
  color: #25D366;
}
.footer-blog .container .footer-container .contact-us .descbox .whats-telegram .contact.telegram small {
  color: #0088cc;
}
.footer-blog .container .footer-container .contact-us .email-phone {
  width: 100%;
  margin-top: 20px;
}
.footer-blog .container .footer-container .contact-us .email-phone li {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.footer-blog .container .footer-container .contact-us .email-phone li h6 {
  font-size: 12px;
  font-family: "Noto Kufi Arabic", sans-serif;
  color: #fff;
}
.footer-blog .container .footer-container .contact-us .email-phone li a {
  display: block;
  margin-right: 10px;
  font-size: 14px;
  font-family: "Noto Kufi Arabic", sans-serif;
  color: #8f6B29;
}
.footer-blog .container .break {
  font-size: 20px;
  font-weight: 500;
  font-family: "Noto Kufi Arabic", sans-serif;
  text-align: center;
  color: #8f6B29;
  padding: 20px;
  position: relative;
  line-height: 1;
}
.footer-blog .container .break span {
  font-size: 14px;
  font-family: "Noto Kufi Arabic", sans-serif;
  display: block;
  margin-top: 5px;
}
.footer-blog .container .break::before {
  content: "";
  width: 40%;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.footer-blog .container .break::after {
  content: "";
  width: 40%;
  height: 1px;
  background-color: #ccc;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}
.footer-blog .container .social-icons-wraper {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: auto;
  margin-top: 10px;
}
.footer-blog .container .social-icons-wraper a {
  display: block;
  width: 40px;
  height: 40px;
  padding: 5px;
}
.footer-blog .container .social-icons-wraper a img {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 480px) {
  .footer-blog .container .footer-container {
    flex-direction: column;
  }
  .footer-blog .container .footer-container .email-form {
    width: 85%;
  }
  .footer-blog .container .footer-container .email-form form {
    width: 90%;
  }
  .footer-blog .container .footer-container .contact-us {
    width: 100%;
    margin-top: 20px;
  }
  .footer-blog .container .footer-container .email-phone {
    width: 100%;
  }
  .footer-blog .container .break {
    font-size: 18px;
    padding: 20px;
  }
  .footer-blog .container .break span {
    font-size: 14px;
  }
  .footer-blog .container .break::before {
    width: 25%;
  }
  .footer-blog .container .break::after {
    width: 25%;
  }
  .footer-blog .container .social-icons-wraper {
    width: 95%;
    margin-top: 10px;
  }
}
.admin-page {
  padding-top: 180px;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(63, 61, 61) 78.9%);
}
.admin-page .admin-page-wraper {
  width: 100%;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
}
.admin-page .admin-page-wraper .sidebar {
  width: 25%;
  padding: 20px;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(85, 78, 78) 78.9%);
  border-radius: 20px;
}
.admin-page .admin-page-wraper .sidebar h2 {
  text-align: center;
  font-size: 24px;
  color: #FFF;
  font-weight: 500;
}
.admin-page .admin-page-wraper .sidebar ul {
  text-align: center;
  margin-top: 30px;
  list-style: none;
}
.admin-page .admin-page-wraper .sidebar ul li {
  margin-bottom: 20px;
}
.admin-page .admin-page-wraper .sidebar ul li a {
  display: block;
  width: 100%;
  color: #FFF;
  font-size: 16px;
  padding: 15px;
  border-radius: 10px;
  background: -webkit-linear-gradient(left, #a18755, #8f6B29);
  font-weight: 500;
}
.admin-page .admin-page-wraper .dashboard-container {
  width: 73%;
  border-radius: 20px;
  padding: 50px 20px;
  background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.93) 11.2%, rgb(85, 78, 78) 78.9%);
}
.admin-page .admin-page-wraper .dashboard-container h3 {
  color: #FFF;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
}
.admin-page .admin-page-wraper .dashboard-container .search-form {
  margin-top: 30px;
}
.admin-page .admin-page-wraper .dashboard-container .search-form input {
  display: block;
  width: 70%;
  margin: auto;
  padding: 10px 0;
  text-align: center;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  outline: none;
  background-color: #111;
  color: #FFF;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper {
  width: 90%;
  margin: auto;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 50px;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .no-items {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #FFF;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .no-items a {
  display: block;
  width: 50%;
  margin: auto;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  background: #209b5d;
  color: #FFF;
  padding: 10px 0;
  border-radius: 10px;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .upload-wraper #upload-img {
  display: none;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .upload-wraper img {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  cursor: pointer;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .upload-wraper.product-upload {
  width: 100%;
  background-color: aquamarine;
  padding: 20px;
  background-color: #111;
  border-radius: 10px;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .upload-wraper.product-upload .upload__image-wrapper {
  display: flex;
  justify-content: flex-end;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .upload-wraper.product-upload .upload__image-wrapper button {
  background-color: transparent;
  border: none;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .upload-wraper.product-upload .upload__image-wrapper .image-item {
  margin-left: 10px;
  text-align: center;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .upload-wraper.product-upload .upload__image-wrapper .image-item .image-item__btn-wrapper button {
  padding: 5px;
  background-color: #c40606;
  color: #FFF;
  margin-left: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .upload-wraper.product-upload .upload__image-wrapper .image-item .image-item__btn-wrapper button.edit-img {
  background-color: rgb(6, 160, 32);
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper form {
  width: 100%;
  margin-top: 20px;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper form input, .admin-page .admin-page-wraper .dashboard-container .add-wraper form textarea, .admin-page .admin-page-wraper .dashboard-container .add-wraper form select {
  display: block;
  width: 100%;
  margin-bottom: 15px;
  padding: 15px;
  text-align: right;
  border-radius: 10px;
  font-size: 18px;
  background-color: #222;
  color: #FFF;
  border: none;
  outline: none;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper form input.submit, .admin-page .admin-page-wraper .dashboard-container .add-wraper form textarea.submit, .admin-page .admin-page-wraper .dashboard-container .add-wraper form select.submit {
  text-align: center;
  background: -webkit-linear-gradient(left, #a18755, #8f6B29);
  cursor: pointer;
  font-weight: bold;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper form select {
  font-size: 16px;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper form select option {
  font-size: 16px;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper form select option.selected {
  background-color: #8f6B29;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .products-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .products-container .product {
  width: 31.6666666667%;
  background-color: #111;
  border-color: #444;
  flex-shrink: 0;
  margin-bottom: 20px;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .products-container .product .product-img a img {
  width: 90%;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .products-container .product .product-details h3 a {
  color: #FFF;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: -webkit-linear-gradient(left, #a18755, #8f6B29);
  border-radius: 10px;
  position: relative;
  margin-bottom: 20px;
  color: #FFF;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .trash, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .order-number, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .trash, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .order-number {
  width: 40px;
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 5px;
  cursor: pointer;
  font-size: 24px;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .coupon-details, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .coupon-details {
  margin-left: 20px;
  padding: 10px 0;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .coupon-details li, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .coupon-details li {
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: #444;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .coupon-details li p, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .coupon-details li p {
  color: #FFF;
  font-size: 16px;
  margin-left: 10px;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .user-page, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .user-page {
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  background: transparent;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .coupon-img, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .coupon-img {
  width: 120px;
  height: 120px;
  border-radius: 20px;
  background-size: cover;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .coupon-img img, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .coupon-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .order-status, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .order-status {
  width: 50%;
  margin: auto;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .order-status .order-deliver, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .order-status .order-paid, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .order-status .order-deliver, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .order-status .order-paid {
  width: 40%;
  display: flex;
  align-items: center;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .order-status .order-deliver img, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .order-status .order-paid img, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .order-status .order-deliver img, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .order-status .order-paid img {
  width: 20px;
  height: 20px;
}
.admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .order-status .order-deliver select, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card .order-status .order-paid select, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .order-status .order-deliver select, .admin-page .admin-page-wraper .dashboard-container .add-wraper .coupon-card a .order-status .order-paid select {
  display: block;
  margin-left: 5px;
  width: 100%;
  padding: 5px;
  border: none;
  background-color: #8f6B29;
  color: #FFF;
  outline: none;
}
.admin-page .admin-page-wraper .dashboard-container .manage-categories {
  width: 100%;
  background-color: #111;
  border-radius: 10px;
  margin-top: 30px;
  padding: 20px 10px 10px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.admin-page .admin-page-wraper .dashboard-container .manage-categories .category {
  display: flex;
  width: 31.6666666667%;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  margin-bottom: 20px;
  flex-shrink: 0;
  border-radius: 10px;
  background: -webkit-linear-gradient(left, #a18755, #8f6B29);
}
.admin-page .admin-page-wraper .dashboard-container .manage-categories .category h6 {
  font-size: 18px;
  color: #FFF;
  margin-left: 10px;
  font-weight: 500;
}
.admin-page .admin-page-wraper .dashboard-container .manage-categories .category img {
  width: 30px;
  height: 30px;
  padding: 5px;
  cursor: pointer;
}
.admin-page .admin-page-wraper .dashboard-container .manage-categories .category img.edit {
  width: 35px;
  height: 35px;
  margin-left: 10px;
}

.spiner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}

svg {
  margin: auto;
}

.spiner path {
  stroke-dasharray: 338px;
  stroke-dashoffset: 338px;
  stroke-linecap: round;
  transform-origin: center;
  animation-name: spiner, rotating, fill;
  animation-duration: 1333ms, 5332ms, 5332ms;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1), steps(4), linear;
  animation-iteration-count: infinite, infinite, infinite;
  fill: none;
  stroke-width: 16px;
  stroke: #007eff;
}

g.spiner {
  animation: rotate 1568.63ms linear infinite;
  transform-origin: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes spiner {
  0% {
    stroke-dashoffset: 337.3px;
  }
  50% {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: -332.9px;
  }
}
@keyframes fill {
  0%, 20% {
    stroke: #111;
  }
  33% {
    stroke: #8f6B29;
  }
  66% {
    stroke: #111;
  }
  100% {
    stroke: #8f6B29;
  }
}/*# sourceMappingURL=main.css.map */