@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@300;400;500;600;700;800&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

::selection{
    background-color: $mainColor;
    color: #FFF;
}


body{
    font-family: 'Poppins', sans-serif;
}

a{
    text-decoration: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}


.container{
    width: 85%;
    margin: auto;
    @media screen and (max-width: 480px){
        width: 95%;
    }
}

.shop-page{
    @media screen and (max-width: 480px){
        padding-bottom: 50px;
    }
}