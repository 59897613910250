.single-blog-page{
    .single-blog-intro{
        height: 100vh;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        .overlay{
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.5) 11.2%, rgba(63, 61, 61, .5) 78.9%);
        }
        .rec{
            position: absolute;
            top: -184px;
            right: 120px;
            width: 368px;
            height: 368px;
            border-radius: 50%;
            opacity: .3;
            background: rgba(0, 0, 0, 0.93);
            &.rec-bottom{
                top: unset;
                bottom: -120px;
                left: 0;
                background: rgba(0, 0, 0, 0.93);
            }
        }
        .container{
            width: 100%;
            .blog-intro-container{
                width: 80%;
                height: fit-content;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                .page-navigation{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 30px;
                    a, span{
                        display: block;
                        margin-right: 15px;
                        font-size: 16px;
                        font-weight: 600;
                        color: #FFF;
                        &.blog-details{
                            color: $mainColor;
                        }
                    }
                }
                .blog-title{
                    width: 80%;
                    margin: auto;
                    text-transform: uppercase;
                    font-size: 60px;
                    font-weight: 600;
                    line-height: 1.2;
                    color: #FFF;
                    text-align: center;
                }
            }
        }
        &::after{
            content: "";
            position: absolute;
            left: 0;
            bottom: -7px;
            width: 100%;
            height: 50px;
            background-image: url(../images/imgs/banner-bg-shape.png);
            background-repeat: repeat-x;
            background-position: center;
        }
    }
    .content{
        width: 68%;
        margin: auto;
        margin-top: 80px;
        .article-img{
            width: 100%;
            height: 395px;
            border-radius: 20px;
            position: relative;
            .overlay{
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
                background-color: rgba(0, 0, 0, 0.1);
            }
            .thum-img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 20px;
            }
            .article-details{
                width: 100%;
                border-radius: 20px;
                background-color: #FFF;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 18px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                box-shadow: rgba(202, 202, 202, 0.25) 0px 9px 16px;
                .author-img-box{
                    display: flex;
                    align-items: center;
                    .author-img{
                        width: 40px;
                        height: 40px;
                        object-fit: cover;
                        border-radius: 50%;
                        margin-right: 15px;
                    }
                    h6{
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: 0.05em;
                        color: $headingColor;
                    }
                }
                .date-box, .comments-box, .views-box{
                    display: flex;
                    align-items: center;
                    margin-left: 15px;
                    img{
                        width: 25px;
                        height: 25px;
                    }
                    h6{
                        font-size: 14px;
                        font-weight: 600;
                        text-transform: uppercase;
                        letter-spacing: 0.05em;
                        color: $textColor;
                        margin-left: 15px;
                    }
                }
            }
        }
        .body{
            margin-top: 50px;
            h1,h2,h3,h4,h5,h6{
                font-weight: 600;
                font-size: 36px;
                line-height: 1.2;
                color: $headingColor;
                margin-bottom: 50px;
            }
            p{
                font-size: 17px;
                font-weight: 500;
                color: $textColor;
                margin-bottom: 15px;
                line-height: 1.6;
                a{
                    color: $mainColor;
                    font-size: inherit;
                    font-weight: inherit;
                    line-height: inherit;
                }
            }
            ul, ol{
                padding: 0 40px;
                li{
                    font-size: 17px;
                    font-weight: 500;
                    color: $textColor;
                    margin-bottom: 10px;
                    line-height: 1.6;
                    a{
                        color: $mainColor;
                        font-size: inherit;
                        font-weight: inherit;
                        line-height: inherit;
                    }
                }
            }
            a{
                font-size: 17px;
                font-weight: 500;
                color: $mainColor;
                margin-bottom: 10px;
                line-height: 1.6;
            }
        }
    }
}

@media screen and (max-width: 480px){
    .single-blog-page{
        .single-blog-intro{
            height: 80vh;
            .rec-bottom{
                bottom: -200px !important;
            }
            .container{
                .blog-intro-container{
                    width: 95%;
                    .blog-title{
                        width: 95%;
                        font-size: 36px;
                    }
                }
            }
        }
        .content{
            width: 95%;
            .article-img{
                height: 195px;
                .article-details{
                    flex-wrap: wrap;
                    bottom: -112px;
                    .author-img-box{
                        flex-shrink: 0;
                    }
                    .date-box, .comments-box, .views-box{
                        flex-shrink: 0;
                        margin-top: 10px;
                        &.date-box{
                            margin-top: 0;
                        }
                    }
                }
            }
            .body{
                margin-top: 170px;
                padding: 0 5px;
                h1,h2,h3,h4,h5,h6{
                    font-size: 25px;
                }
                p{
                    font-size: 14px;
                    text-wrap: balance;
                }
                ul, ol{
                    padding: 0 30px;
                    li{
                        font-size: 14px;
                        font-weight: 500;
                        color: $textColor;
                        margin-bottom: 10px;
                        line-height: 1.6;
                    }
                }
                a{
                    font-size: 14px;
                }
            }
        }
    }
}