.navbar {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding: 18px 0;
    transition: all ease-in-out .3s;
    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 1px;
        color: #fff;
        -webkit-box-shadow: inset 0 0 0 1px;
        box-shadow: inset 0 0 0 1px;
        opacity: 0.2;
    }
    .container {
        .nav-container {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .logo {
                width: 70px;
                img {
                    width: 100%;
                    object-fit: cover;
                }
            }
            .nav-wraper {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 70%;
                ul {
                    width: 100%;
                    text-align: right;
                    li {
                        display: inline-block;
                        margin-right: 15px;
                        text-align: left;
                        a {
                            font-size: 12px;
                            font-weight: 600;
                            color: #fff;
                            padding: 10px;
                            &.active {
                                color: $mainColor;
                            }
                            &:hover {
                                color: $mainColor;
                            }
                        }
                    }
                    &.menu-ar{
                        li{
                            a{
                                font-family: 'Noto Kufi Arabic' !important;
                            }
                        }
                    }
                }
                .nav-icons {
                    display: flex;
                    align-items: center;
                    .cart,
                    .search, .close-menu {
                        width: 20px;
                        height: 20px;
                        margin-right: 15px;
                        position: relative;
                        span{
                            width: 12px;
                            height: 12px;
                            padding-top: 2px;
                            border-radius: 50%;
                            background-color: $mainColor;
                            color: #000;
                            position: absolute;
                            top: -5px;
                            right: -5px;
                            font-size: 10px;
                            font-weight: bold;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                        &:last-of-type {
                            margin-right: 0;
                        }
                    }
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        cursor: pointer;
                    }
                    .mobile-menu{
                        width: 20px;
                        height: 20px;
                        margin-right: 15px;
                        display: none;
                    }
                    .close-menu{
                        display: none;
                    }
                }
            }
        }
    }
}

//sm screen
@media screen and (max-width: 991px){
    .navbar{
        .container{
            .nav-container{
                .nav-wraper{
                    ul{
                        position: fixed;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        width: 60%;
                        padding: 80px 0 0 10px;
                        background-color: #000;
                        transition: all ease-in-out .3s;
                        li{
                            display: block;
                            margin-bottom: 20px;
                        }
                        &.hide-menu{
                            margin-right: -60%;
                        }
                    }
                    .nav-icons{
                        div{
                            margin-left: 20px;
                        }
                        .mobile-menu{
                            display: block;
                        }
                        .close-menu{
                            display: block;
                        }
                    }
                }
            }
        }
    }
}