.profile-page {
    .page-intro {
        height: 100vh;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        .overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background: linear-gradient(109.6deg, rgba(0, 0, 0, 0.95) 11.2%, rgb(63, 61, 61) 78.9%);
        }
        .rec {
            position: absolute;
            top: -184px;
            right: 120px;
            width: 368px;
            height: 368px;
            border-radius: 50%;
            opacity: 0.3;
            background: rgba(0, 0, 0, 0.93);
            &.rec-bottom {
                top: unset;
                bottom: -120px;
                left: 0;
                background: rgba(0, 0, 0, 0.93);
            }
        }
        .container {
            width: 100%;
            .page-intro-container {
                width: 80%;
                height: fit-content;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                .page-navigation {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-bottom: 30px;
                    a,
                    span {
                        display: block;
                        margin-right: 15px;
                        font-size: 16px;
                        font-weight: 600;
                        color: #fff;
                        &.page-name {
                            color: $mainColor;
                        }
                    }
                }
                .page-title {
                    width: 80%;
                    margin: auto;
                    text-transform: uppercase;
                    font-size: 60px;
                    font-weight: 600;
                    line-height: 1.2;
                    color: #fff;
                    text-align: center;
                }
            }
        }
        &::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: -7px;
            width: 100%;
            height: 50px;
            background-image: url(../images/imgs/banner-bg-shape.png);
            background-repeat: repeat-x;
            background-position: center;
        }
    }
    .profile-container {
        margin-top: 80px;
        h2 {
            font-size: 36px;
            font-weight: 600;
            text-align: center;
            color: $headingColor;
        }
        .profile-wraper {
            width: 85%;
            margin: auto;
            display: flex;
            justify-content: space-between;
            .wishlist {
                width: 49%;
                height: fit-content;
                border: 1px solid $mainColor;
                border-radius: 5px;
                padding: 20px;
                h4 {
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 25px;
                        margin-right: 5px;
                    }
                }
                .no-items {
                    margin-top: 10px;
                    text-align: center;
                    font-size: 18px;
                }
                .product-wraper {
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .fav-product {
                        width: 100%;
                        padding: 30px 10px;
                        margin-bottom: 10px;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        background-color: rgb(240, 247, 243);
                        position: relative;
                        .trash {
                            position: absolute;
                            top: 8px;
                            right: 8px;
                            padding: 5px;
                            width: 35px;
                            cursor: pointer;
                        }
                        .product-img {
                            width: 100px;
                            height: 140px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img {
                                width: 80%;
                                object-fit: cover;
                            }
                        }
                        .product-details {
                            margin-left: 20px;
                            h3 {
                                font-size: 16px;
                                font-weight: 600;
                                color: $headingColor;
                            }
                            .product-price {
                                display: flex;
                                justify-content: center;
                                p {
                                    margin-left: 10px;
                                    font-size: 16px;
                                    font-weight: bold;
                                    &.old-price {
                                        color: #999;
                                        text-decoration: line-through;
                                    }
                                }
                            }
                            .add-to-cart {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                background-color: $mainColor;
                                padding: 5px;
                                margin-top: 15px;
                                cursor: pointer;
                                h5 {
                                    color: #fff;
                                    font-size: 14px;
                                    font-weight: 500;
                                }
                                img {
                                    width: 25px;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
            .orders {
                width: 49%;
                height: fit-content;
                border: 1px solid $mainColor;
                border-radius: 5px;
                padding: 10px;
                h4 {
                    font-size: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img {
                        width: 25px;
                        margin-right: 5px;
                    }
                }
                .no-items {
                    margin-top: 10px;
                    text-align: center;
                    font-size: 18px;
                }
                .orders-wraper {
                    width: 100%;
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .order {
                        width: 100%;
                        padding: 10px;
                        border-radius: 20px;
                        background-color: rgb(221, 221, 228);
                        margin-bottom: 10px;
                        .order-number {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            font-size: 16px;
                            font-weight: 500;
                            margin-bottom: 20px;
                            small {
                                font-size: 24px;
                                margin-right: auto;
                            }
                        }
                        .products-wraper {
                            width: 100%;
                            .product-order {
                                width: 100%;
                                display: flex;
                                justify-content: flex-end;
                                margin-bottom: 10px;
                                .product-order-details {
                                    width: 100%;
                                    margin-right: 20px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    .name-size {
                                        h3 {
                                            font-size: 16px;
                                            color: $headingColor;
                                        }
                                        p {
                                            font-size: 16px;
                                            text-align: center;
                                            color: $headingColor;
                                            text-transform: uppercase;
                                        }
                                    }
                                    .product-price {
                                        p {
                                            font-size: 18px;
                                            color: $headingColor;
                                        }
                                    }
                                }
                                .product-img {
                                    width: 80px;
                                    height: 100px;
                                    background-color: #999;
                                    border-radius: 10px;
                                    padding: 5px;
                                    position: relative;
                                    span {
                                        width: 20px;
                                        height: 20px;
                                        font-size: 12px;
                                        border-radius: 50%;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        background-color: #333;
                                        color: #fff;
                                        position: absolute;
                                        top: -5px;
                                        left: 10px;
                                    }
                                    img {
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                        border-radius: 10px;
                                    }
                                }
                            }
                        }
                        .order-status {
                            display: flex;
                            justify-content: space-between;
                            margin-top: 30px;
                            p {
                                width: calc(95% / 3);
                                font-size: 16px;
                                text-align: center;
                                &.price {
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (max-width: 576px){
    .profile-page{
        .profile-container{
            .profile-wraper{
                width: 98%;
                flex-direction: column-reverse;
                .wishlist{
                    width: 100%;
                    flex-shrink: 0;
                    margin-bottom: 30px;
                }
                .orders{
                    width: 100%;
                    flex-shrink: 0;
                }
            }
        }
    }
}