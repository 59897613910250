.footer {
    padding: 60px 0 30px 0;
    background-color: #1a1a1a;
    .container {
        .footer-container {
            display: flex;
            justify-content: space-between;
            .info-footer {
                width: calc(95% / 3);
                .logo-footer {
                    margin-bottom: 10px;
                    img {
                        width: 60px;
                    }
                }
                .info-footer-wraper {
                    p {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 24px;
                        max-width: 255px;
                        color: #cacaca;
                    }
                }
                .copyright {
                    margin-top: 25px;
                    p {
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 1.5;
                        color: #989796;
                        a{
                            color: #989796;
                            &:hover{
                                color: #FFF;
                            }
                        }
                    }
                }
            }
            .links-footer{
                width: calc(95% / 3);
                h6{
                    font-size: 18px;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    margin-bottom: 10px;
                    color: #FFF;
                }
                .links-footer-wraper{
                    display: flex;
                    ul{
                        list-style: none;
                        li{
                            padding: 10px 0;
                            a{
                                position: relative;
                                color: #FFF;
                                display: block;
                                padding-left: 25px;
                                line-height: 1.2em;
                                text-decoration: none;
                                font-size: 12px;
                                font-weight: 300;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                &:hover{
                                    color: $mainColor;
                                }
                                &::before{
                                    content: "";
                                    width: 4px;
                                    height: 4px;
                                    border: 1px solid $mainColor;
                                    border-bottom-color: transparent;
                                    border-left-color: transparent;
                                    position: absolute;
                                    left: 0;
                                    top: 3px;
                                    transform: rotate(45deg);
                                }
                            }
                        }
                        &:last-of-type{
                            margin-left: 50px;
                        }
                    }
                }
            }
            .contacts-footer{
                width: calc(95% / 3);
                h6{
                    font-size: 18px;
                    font-weight: 600;
                    text-transform: uppercase;
                    letter-spacing: 2px;
                    margin-bottom: 10px;
                    color: #FFF;
                }
                .contacts-footer-wraper{
                    list-style: none;
                    li{
                        margin-bottom: 10px;
                        padding: 10px 0;
                        a{
                            display: flex;
                            font-size: 14px;
                            font-weight: 300;
                            color: #FFF;
                            img{
                                width: 22px;
                                display: block;
                                margin-right: 25px;
                                opacity: .9;
                            }
                        }
                    }
                }
            }
        }
    }
    &.ar{
        font-family: 'Noto Kufi Arabic' !important;
    }
}

@media screen and (max-width: 480px){
    .footer{
        .container{
            .footer-container{
                flex-direction: column;
                .info-footer, .links-footer, .contacts-footer{
                    width: 100%;
                    margin-bottom: 20px;
                    &.contacts-footer{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}